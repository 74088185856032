/* eslint-disable */

import inspect from "./inspect";
import { padLeftZero } from "./string";

const dateExtension = {
    formatDate: (value, format) => {
        if (!value) {
            return "";
        }

        let parsed = new Date(value);
        if (!inspect.isDate(parsed)) {
            return parsed;
        }

        const year = parsed.getFullYear();
        const month = parsed.getMonth() + 1;
        const date = parsed.getDate();
        const h = parsed.getHours();
        const m = parsed.getMinutes();
        const s = parsed.getSeconds();
        const ms = parsed.getMilliseconds();

        let result = format || 'yyyy/MM/dd HH:mm:ss'; // .fff
        if (~result.indexOf('yyyy'))
            result = result.replace('yyyy', padLeftZero(year, 4));
        if (~result.indexOf('MM'))
            result = result.replace('MM', padLeftZero(month, 2));
        if (~result.indexOf('dd'))
            result = result.replace('dd', padLeftZero(date, 2));
        if (~result.indexOf('HH'))
            result = result.replace('HH', padLeftZero(h, 2));
        if (~result.indexOf('mm'))
            result = result.replace('mm', padLeftZero(m, 2));
        if (~result.indexOf('ss'))
            result = result.replace('ss', padLeftZero(s, 2));
        if (~result.indexOf('fff'))
            result = result.replace('fff', padLeftZero(ms, 3));

        return result;
    },
    getStartTimeOfDay(date) {
        if (!inspect.isDate(date)) return date;
        return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
    },
    getEndTimeOfDay(date) {
        if (!inspect.isDate(date)) return date;
        return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 999);
    },
    getFirstDayOfMonth(date) {
        if (!inspect.isDate(date)) return date;

        return new Date(date.getFullYear(), date.getMonth(), 1);
    },
    getLastDayOfMonth(date) {
        if (!inspect.isDate(date)) return date;

        return new Date(date.getFullYear(), date.getMonth() + 1, 0, 23, 59, 59, 999);
    }
};

export default dateExtension;