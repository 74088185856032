/* eslint-disable */

import { isArray, isDate, isObject, isPlainObject } from "./inspect"
import { hasOwnProperty } from "./object";

export const clone = (source) => {
    // Handle Date
    if (isDate(source)) {
        let copy = new Date();
        copy.setTime(source.getTime());
        return copy;
    }

    // Handle Array
    if (isArray(source)) {
        let copy = [];
        for (let i = 0; i < source.length; i++)
            copy[i] = clone(source[i]);

        return copy;
    }

    // Handle Object
    if (isObject(source)) {
        let copy = {};
        for (const key in source)
            if (hasOwnProperty(source, key)) copy[key] = clone(source[key]);

        return copy;
    }

    return source;
}

// to be continued
// Note: If the options is nested, the reference may still be copied.
export const cloneDeep = (...options) => {
    for (let i = 1; i < options.length; i++) {
        // Use for in will list all the properties including inheritance.
        // So use hasOwnProperty to identify which belong to current object itself.
        const target = options[0],
            source = options[i];
        for (const key in source) {
            if (hasOwnProperty(source, key)) {
                const isArr = isArray(source[key]);
                if (source[key] && (isArr || isPlainObject(source[key]))) {
                    if (isArr) {
                        target[key] = target[key] && isArray(target[key]) ? target[key] : [];
                    } else {
                        target[key] = target[key] && isPlainObject(target[key]) ? target[key] : {};
                    }


                    cloneDeep(target[key], source[key]);
                } else {
                    target[key] = source[key];
                }
            }
        }
    }
    return options[0];
};