
/* eslint-disable */

import { onMounted } from "vue";
import CloudFun, { defineComponent, ref, reactive, computed, Sorting, SortOrder, Condition, Operator, LogicalConnective, Model } from "@cloudfun/core";
import { method } from "node_modules/@types/lodash";
import { VxeColumnPropTypes, VxeGridInstance, VxeGridProps, VxeFormPropTypes, Column } from "vxe-table";
import { cityList, districtList, GetZip } from '@/libs/city';
import FileUpload from 'vue-upload-component';

export default defineComponent({
  components:{    
    FileUpload,    
  },
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref({} as VxeGridInstance);
    const loading = ref(false);
    const editingRow = ref(null);
    const keyword = ref(""); 
    const selectdCity = ref("");
    const uploader1 = ref({});
    const uploader2 = ref({});
    const file1 = ref([]);
    const file2 = ref([]);
    const customFilters = ref<Condition[]>([]);
    const isRowSelected = computed(() => (grid.value.getRadioRecord && grid.value.getRadioRecord()) || (grid.value.getCheckboxRecords && grid.value.getCheckboxRecords().length > 0));
    const isEditing = computed(() => editingRow.value != null && grid.value.isActiveByRow(editingRow.value));
    var printColumns = [
      { field: 'MemberNumber' }, 
      { field: 'Principal' },
      { field: 'StatusString' },
      { field: 'Person.Fax' },
      { field: 'Nickname' },
      { field: 'Person.Address.PostalCode' },
      { field: 'Person.Address.City' },      
      { field: 'Person.Address.District' }, 
      { field: 'Person.Address.Line' },
    ]

    const groupArray = ['ContactJobTitle', 'Contact', 'ContactEmail', 'Organization', 'Introduction','OrganizationEnglish','Tax', 'OrgUrl', 'Principal' ,'BuildTime','Capital','MemberTotal','Representative1','Email1','JobTitle1','Representative2','Email2','JobTitle2','Representative3','Email3','JobTitle3','MainItem','Technology',]
    const personalArray = ['JobTitle', 'JobType', 'Unit', 'Education', 'School', 'Person.BirthDate', 'Person.Sex']

    const gridOptions = reactive({
      keepSource: true,
      round: true,
      border: true,
      stripe: true,
      highlightHoverColumn: true,
      highlightHoverRow: true,
      resizable: true,
      loading: false,
      customConfig: { storage: true },
      filterConfig: { remote: true },
      sortConfig: { remote: true, multiple: true },
      //editConfig: { trigger: 'manual', mode: 'row', showIcon: false, autoClear: false },
      pagerConfig: { currentPage: 1, pageSize: 10, pageSizes: [5, 10, 20], layouts: [ 'PrevJump', 'PrevPage', 'Jump', 'PageCount', 'NextPage', 'NextJump', 'Sizes', 'Total' ] },
      printConfig: { sheetName: '會員清單', columns: printColumns, modes: [ 'current', 'selected', 'all' ] },
      exportConfig: { filename: '會員清單', type: 'csv', types: [ 'html', 'csv' ], mode: 'all', modes: [ 'current', 'selected', 'all' ], columns: printColumns },
      columns: [
        { type: "checkbox", width: 35, fixed: 'left', resizable: false },
        { 
          field: "Nickname", title: "姓名", showHeaderOverflow: true, showOverflow: true, sortable: true,
          filters: [{ checked: false, label: '包含' }],
          slots: { filter: 'name_filter' }
        },
        { field: "Account", title: "帳號", showHeaderOverflow: true, showOverflow: true, resizable: false },
        { field: "StatusString", title: "帳號狀態", showHeaderOverflow: true, showOverflow: true, resizable: false },               
        { field: "Email", title: "Email", showHeaderOverflow: true, showOverflow: true, resizable: false },
        { field: "Person.Phone", title: "聯絡電話", showHeaderOverflow: true, showOverflow: true, resizable: false },
        { field: "Person.Address.City", title: "縣市", showHeaderOverflow: true, showOverflow: true, resizable: false },
        // { field: "Person.Address.Full", title: "完整地址", showHeaderOverflow: true, showOverflow: true, resizable: false, visible: true, width:300 },
        { title: "操作", width: 100, fixed: 'right', align: 'center', resizable: false, slots: { default: 'operate' } },
      ],
      proxyConfig: {
        message: false,
        sort: true, 
        filter: true,
        props: { result: 'data', total: 'totalCount' }, 
        ajax: {
          query: (params) => {    
            //params.sorts.push({ column: Column.prototype, order: 'desc', sortBy: 'Number', property:'Number' });    
            
            const queryParams: { page: number, pageSize: number, keyword: string, city: string, sortings?: Sorting[], condition: Condition, } = { 
              page: params.page.currentPage, 
              pageSize: params.page.pageSize, 
              keyword: keyword.value,
              city: selectdCity.value,
              sortings: params.sorts.filter(e => e.property).map(e => new Sorting(e.property, e.order === 'desc' ? SortOrder.Descending : SortOrder.Ascending)), // sorts
              condition: new Condition(customFilters.value) // custom filters
            };
            
            // filters
            const filters = params.filters ? params.filters.filter(e => e.values.length) : null;
            if (filters && filters.length) {
              const gridCondition = new Condition();
              // colum filters
              filters.forEach(filter => { 
                const columnCondition = new Condition();
                filter.values.forEach(subFilter => {
                  if (subFilter && subFilter instanceof Condition) {
                    const condition = subFilter as Condition;
                    if (condition.connective === LogicalConnective.And) columnCondition.and(condition);
                    else columnCondition.or(condition);
                  }
                });
                gridCondition.and(columnCondition);
              });
              queryParams.condition.and(gridCondition);
            }
            return new Promise((resolve, reject) => model?.dispatch('member/load', queryParams).then(
              payload => {
                console.log('promise',payload)
                resolve(payload)
              },
              reason => {               
                CloudFun.send('error', { subject: '讀取失敗', content: reason })
                reject(reason);
              }
            ));
          },
          delete: (params) => {
            const deleteParams = { deleteRows: params.body.removeRecords };
            return new Promise((resolve, reject) => model?.dispatch('member/save', deleteParams).then(
              payload => resolve(payload),
              reason => {
                CloudFun.send('error', { subject: '刪除失敗', content: reason })
                reject(reason);
              }
            ));
          },
          save: (params) => {
            const saveParams = { insertRows: params.body.insertRecords, updateRows: params.body.updateRecords, deleteRows: params.body.removeRecords };
            return new Promise((resolve, reject) => model?.dispatch('member/save',  saveParams).then(
              payload => { 
                editingRow.value = null;
                resolve(payload)
              },
              reason => {
                CloudFun.send('error', { subject: '保存失敗', content: reason })
                reject(reason);
              }
            ));
          }
        }
      }
    } as VxeGridProps);

    //modal 執行 新增or修改
    const submitSave = () =>{
      var method = form.model == 'insert' ? 'insert' : 'update';

      //確認密碼
      if((form.formData.Password || form.formData.Password2) && form.formData.Password != form.formData.Password2) {
         CloudFun.send('error', { subject: '操作失敗！', content: '密碼不一致，請確認輸入！' })
        return;
      }

      gridOptions.loading = true;
      form.formData.Person.Address.PostalCode = GetZip(form.formData.Person.Address.City, form.formData.Person.Address.District);
      
      model?.dispatch(`member/${method}`, form.formData).then(
        paylod => {
          //var sorting = new Sorting('CreatedTime', 1);          
          grid.value.commitProxy('query').finally(() => {  });
          CloudFun.send('info', { subject: method == 'insert' ? '新增成功' : '更新成功', content: method == 'insert' ? '會員新增完成' : '會員更新完成' })
        },
        failure => CloudFun.send('error', { subject: '操作失敗！', content: failure })
      ).finally(() => { 
        form.showEdit = false; 
        gridOptions.loading = false;
      });
    }

    const changeCity = () => {      
      form.DistrictsList = districtList(form.formData.Person.Address.City); 
      var districtOption = form.formItems[9];      
      if (districtOption.itemRender) {
        districtOption.itemRender.options = form.DistrictsList;
        form.formData.Person.Address.District = form.DistrictsList[0]['value'];
        //console.log(GetZip(form.formData.Person.Address.City, form.formData.Person.Address.District))
      }
    }

    const VisibleByType = (data: any) =>{
      if(groupArray.find(e => e == data.property) && form.formData.Type == 1) return true;
      if(personalArray.find(e => e == data.property) && form.formData.Type == 0) return true;
      return false;
    };

    const ChangeByType = () =>{
      for(var i in groupArray) if(form.formRules[groupArray[i]] && groupArray[i] != 'Email2' && groupArray[i] != 'Email3'){ form.formRules[groupArray[i]][0].required = form.formData.Type == 1 ? true : false; }
      for(var j in personalArray) if(form.formRules[personalArray[j]]) form.formRules[personalArray[j]][0].required = form.formData.Type == 0 ? true : false;
    };


    const form = reactive({
      model: 'insert',   
      CityList: [],
      DistrictsList: [],
      CategoryList: [],
      StatusList: [],
      TypeList: [],
      selectRow: true,
      showEdit: false,
      formData: {
        Id: "00000000-0000-0000-0000-000000000000",
        CreatedTimeString: '',
        Nickname: '',
        CreatedTime:'',
        Status: 0,
        StatusString: '',
        Email: '',        
        Account: '',        
        Password: '',
        Password2: '',
        Remark: '',   
        Classifications: [], 
        JobTitle: '',
        JobType: '',
        Unit: '',
        Education: '',
        School: '',
        Organization: '',
        OrganizationEnglish: '',
        Tax: '',
        OrgUrl: '',
        Principal: '',
        BuildTime: '',
        Capital: 0,
        MemberTotal: 0,
        Representative1: '',
        Email1: '',
        JobTitle1: '',
        Representative2: '',
        Email2: '',
        JobTitle2: '',
        Representative3: '',
        Email3: '',
        JobTitle3: '',
        Contact: '',
        ContactEmail: '',
        ContactJobTitle: '',
        MainItem: '',
        Technology: '',
        Type: 0,
        Introduction: '',
        Person: {
          Id: 0,
          Fax: '',
          Phone: '',
          Sex: 0,
          BirthDate: '',
          Address:{
            Id: 0,          
            City: '',
            District: '',
            Line: '', 
            PostalCode: '',           
          }
        },
        ProductPhoto1: {
          Id: 0,
          Uri: '',
        },
        ProductPhoto2: {
          Id: 0,
          Uri: '',
        },
      },
      formRules: {
        Nickname: [
          { required: true, message: '請輸入姓名' },          
        ],
        Email: [
          { required: true, message: '請輸入Email' },          
        ],
        Account: [
          { required: true, message: '請輸入帳號' }
        ],
        Status: [
          { required: true, message: '請輸入狀態' }
        ],        
        Password: [
          { required: false, message: '請輸入密碼(至少八位英數字、特殊符號)', pattern: /^((?=.{8,}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*|(?=.{8,}$)(?=.*\d)(?=.*[a-zA-Z])(?=.*[!\u0022#$%&'()*+,./:;<=>?@[\]\^_`{|}~-]).*)/ }
        ],
        Password2: [
          { required: false, message: '請輸入確認密碼(至少八位英數字、特殊符號)', pattern: /^((?=.{8,}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*|(?=.{8,}$)(?=.*\d)(?=.*[a-zA-Z])(?=.*[!\u0022#$%&'()*+,./:;<=>?@[\]\^_`{|}~-]).*)/ }
        ],
        'Person.Address.City': [
          { required: true, message: '請輸入縣市' }
        ],
        'Person.Address.District': [
          { required: true, message: '請輸入行政區' }
        ],
        'Person.Address.Line': [
          { required: true, message: '請輸入詳細地址' }
        ],
        'Profile.Fax': [
          { message: '無效的傳真號碼', pattern: new RegExp("^([\\+][0-9]{1,3}([ \\.\\-])?)?([\\(][0-9]{1,6}[\\)])?([0-9 \\.\\-|#]{1,32})(([A-Za-z \\:]{1,11})?[0-9]{1,4}?)$") }
        ],
        Type: [
          { required: true, message: '請輸入會員類型' }
        ],
        JobTitle: [ //12-15 = 個人會員必填
          { required: true, message: '此欄位必填' }
        ],
        Unit: [
          { required: true, message: '此欄位必填' }
        ],
        'Person.BirthDate': [
          { required: true, message: '此欄位必填' }
        ],
        'Person.Sex': [
          { required: true, message: '此欄位必填' }
        ],
        Organization: [ //16-29 = 團體會員必填
          { required: true, message: '此欄位必填' }
        ],
        Introduction: [
          { required: true, message: '此欄位必填' }
        ],
        Tax: [
          { required: true, message: '此欄位必填' }
        ],
        OrgUrl: [
          { required: true, message: '此欄位必填', pattern: new RegExp("(https?:\\/\\/[\\w-\\.]+(:\\d+)?(\\/[~\\w\\/\\.]*)?(\\?\\S*)?(#\\S*)?)") }
        ],
        Principal: [
          { required: true, message: '此欄位必填' }
        ],
        BuildTime: [
          { required: true, message: '此欄位必填' }
        ],
        Capital: [
          { required: true, message: '此欄位必填' }
        ],
        MemberTotal: [
          { required: true, message: '此欄位必填' }
        ],
        Representative1: [
          { required: true, message: '此欄位必填' }
        ],
        Email1: [
          { required: true, message: '此欄位必填', pattern: new RegExp("^\\w+((-\\w+)|(\\.\\w+))*\\@[A-Za-z0-9]+((\\.|-)[A-Za-z0-9]+)*\\.[A-Za-z]+$") }
        ],
        Email2: [
          { required: false, message: '請輸入正確Email', pattern: new RegExp("^\\w+((-\\w+)|(\\.\\w+))*\\@[A-Za-z0-9]+((\\.|-)[A-Za-z0-9]+)*\\.[A-Za-z]+$") }
        ],
        Email3: [
          { required: false, message: '請輸入正確Email', pattern: new RegExp("^\\w+((-\\w+)|(\\.\\w+))*\\@[A-Za-z0-9]+((\\.|-)[A-Za-z0-9]+)*\\.[A-Za-z]+$") }
        ],
        JobTitle1: [
          { required: true, message: '此欄位必填' }
        ],
        Contact: [
          { required: true, message: '此欄位必填' }
        ],
        ContactEmail: [
          { required: true, message: '此欄位必填', pattern: new RegExp("^\\w+((-\\w+)|(\\.\\w+))*\\@[A-Za-z0-9]+((\\.|-)[A-Za-z0-9]+)*\\.[A-Za-z]+$") }
        ],
        ContactJobTitle: [
          { required: true, message: '此欄位必填' }
        ],
        MainItem: [
          { required: true, message: '此欄位必填' }
        ],
        Technology: [
          { required: true, message: '此欄位必填' }
        ],
        Classifications: [
          { required: true, message: '此欄位必填' }
        ],
      } as VxeFormPropTypes.Rules,
      formItems: [
        { field: 'Nickname', title: '姓名', span: 12, itemRender: { name: '$input', props: { placeholder: '姓名' } } },
        { field: 'Status', title: '帳號狀態', span: 12, itemRender: { name: '$select', options:[] } },
        { field: 'Account', title: '帳號', span: 12, itemRender: { name: '$input', props: { placeholder: '帳號' } } },
        { field: 'Email', title: 'Email', span: 12, itemRender: { name: '$input', props: { placeholder: 'Email' } } },
        { field: 'Password', title: '密碼', span: 12, itemRender: { name: '$input', props: { type: 'password', placeholder: '密碼' } } },
        { field: 'Password2', title: '確認密碼', span: 12, itemRender: { name: '$input', props: { type: 'password', placeholder: '確認密碼' } } },
        { field: 'Person.Phone', title: '聯絡電話', span: 12, itemRender: { name: '$input', props: { placeholder: '聯絡電話' } } },
        { field: 'Person.Fax', title: '傳真', span: 12, itemRender: { name: '$input', props: { placeholder: '傳真' } } },
        { field: 'Person.Address.City', title: '縣市', span: 6, itemRender: { name: '$select', options:[], events: { change: changeCity } } },
        { field: 'Person.Address.District', title: '行政區', span: 6, itemRender: { name: '$select', options:[] } },
        { field: 'Person.Address.Line', title: '詳細地址', span: 12, itemRender: { name: '$input', props: { placeholder: '詳細地址' } } },
        { field: 'Type', title: '會員類型', span: 12, itemRender: { name: '$select', options: [], events: { change: ChangeByType } } },
        { field: 'Person.Sex', title: '性別', span: 12, itemRender: { name: '$radio', options: [ { label: '女', value: 0 }, { label: '男', value: 1 }] }, visibleMethod: VisibleByType },
        { field: 'JobTitle', title: '職稱', span: 12, itemRender: { name: '$input', props: { placeholder: '職稱' } }, visibleMethod: VisibleByType },
        { field: 'JobType', title: '工作性質', span: 12, itemRender: { name: '$input', props: { placeholder: '工作性質' } }, visibleMethod: VisibleByType },
        { field: 'Unit', title: '服務單位', span: 12, itemRender: { name: '$input', props: { placeholder: '服務單位' } }, visibleMethod: VisibleByType },
        { field: 'Education', title: '學歷', span: 12, itemRender: { name: '$input', props: { placeholder: '學歷' } }, visibleMethod: VisibleByType },
        { field: 'School', title: '科系', span: 12, itemRender: { name: '$input', props: { placeholder: '科系' } }, visibleMethod: VisibleByType },
        { field: 'Person.BirthDate', title: '出生年月日', span: 12, itemRender: { name: '$input', props: { type: 'date', placeholder: '出生年月日' } }, visibleMethod: VisibleByType },
        { field: 'Organization', title: '機關名稱', span: 12, itemRender: { name: '$input', props: { placeholder: '機關名稱' } }, visibleMethod: VisibleByType },
        { field: 'OrganizationEnglish', title: '公司英文名稱', span: 12, itemRender: { name: '$input', props: { placeholder: '公司英文名稱' } }, visibleMethod: VisibleByType },
        { field: 'Tax', title: '統一編號', span: 12, itemRender: { name: '$input', props: { placeholder: '統一編號' } }, visibleMethod: VisibleByType },
        { field: 'OrgUrl', title: '網址', span: 12, itemRender: { name: '$input', props: { placeholder: '網址' } }, visibleMethod: VisibleByType },
        { field: 'Principal', title: '負責人', span: 12, itemRender: { name: '$input', props: { placeholder: '負責人' } }, visibleMethod: VisibleByType },
        { field: 'BuildTime', title: '成立日期', span: 8,  itemRender: { name: '$input', props: { type: 'date', placeholder: '成立日期' } }, visibleMethod: VisibleByType },
        { field: 'Capital', title: '資本額', span: 8, itemRender: { name: '$input', props: { type: 'number', placeholder: '資本額' } }, visibleMethod: VisibleByType },
        { field: 'MemberTotal', title: '員工人數', span: 8, itemRender: { name: '$input', props: { type: 'number', placeholder: '員工人數' } }, visibleMethod: VisibleByType },
        { field: 'Representative1', title: '會員代表1', span: 8, itemRender: { name: '$input', props: { placeholder: '會員代表' } }, visibleMethod: VisibleByType },
        { field: 'Email1', title: 'Email(1)', span: 8, itemRender: { name: '$input', props: { placeholder: '會員代表Email' } }, visibleMethod: VisibleByType },
        { field: 'JobTitle1', title: '職稱(1)', span: 8, itemRender: { name: '$input', props: { placeholder: '會員代表職稱' } }, visibleMethod: VisibleByType },
        { field: 'Representative2', title: '會員代表2', span: 8, itemRender: { name: '$input', props: { placeholder: '會員代表' } }, visibleMethod: VisibleByType },
        { field: 'Email2', title: 'Email(2)', span: 8, itemRender: { name: '$input', props: { placeholder: '會員代表Email' } }, visibleMethod: VisibleByType },
        { field: 'JobTitle2', title: '職稱(2)', span: 8, itemRender: { name: '$input', props: { placeholder: '會員代表職稱' } }, visibleMethod: VisibleByType },
        { field: 'Representative3', title: '會員代表3', span: 8, itemRender: { name: '$input', props: { placeholder: '會員代表' } }, visibleMethod: VisibleByType },
        { field: 'Email3', title: 'Email(3)', span: 8, itemRender: { name: '$input', props: { placeholder: '會員代表Email' } }, visibleMethod: VisibleByType },
        { field: 'JobTitle3', title: '職稱(3)', span: 8, itemRender: { name: '$input', props: { placeholder: '會員代表職稱' } }, visibleMethod: VisibleByType },
        { field: 'Contact', title: '聯絡人', span: 8, itemRender: { name: '$input', props: { placeholder: '聯絡人' } }, visibleMethod: VisibleByType },
        { field: 'ContactEmail', title: '聯絡人Email', span: 8, itemRender: { name: '$input', props: { placeholder: '聯絡人Email' } }, visibleMethod: VisibleByType },
        { field: 'ContactJobTitle', title: '聯絡人職稱', span: 8, itemRender: { name: '$input', props: { placeholder: '聯絡人職稱' } }, visibleMethod: VisibleByType },
        { field: 'MainItem', title: '主要營業項目', span: 12, itemRender: { name: '$input', props: { placeholder: '主要營業項目' } }, visibleMethod: VisibleByType },
        { field: 'Technology', title: '主要技術', span: 12, itemRender: { name: '$input', props: { placeholder: '主要技術' } }, visibleMethod: VisibleByType },
        { field: 'Classifications', title: '產業類別', span: 24, itemRender: { name: '$checkbox', options: [] } },
        { field: 'Introduction', title: '公司簡介', span: 24, itemRender: { name: '$textarea', props: { autosize: { minRows: 6, maxRows: 6 }, placeholder: '內容' } }, visibleMethod: VisibleByType },
        { align: 'right', span: 24, titleAlign: 'left', itemRender: { name: '$buttons', children: [{ props: { type: 'submit', content: '確認', status: 'primary' } } , { props: { type: 'reset', content: '重置' } }] } } //, events: { click: save }
      ] as VxeFormPropTypes.Items
    });

    const passwordCheck = (val: boolean) => {
      form.formRules['Password'][0].required = val
      form.formRules['Password2'][0].required = val
    }

    const cityInit = () =>{      
      form.DistrictsList = districtList('台北市');
      var districtOption = form.formItems[9];      
      if (districtOption.itemRender) {    
        form.formData.Person.Address.City = form.CityList[0]['value'],    
        districtOption.itemRender.options = form.DistrictsList;
        form.formData.Person.Address.District = form.DistrictsList[0]['value']              
      }
    };

    const getClassification = () => {
        return new Promise((resolve, reject) => {
          model?.dispatch('classification/query', { keyword: null, skipRows: null, takeRows: null, simple: true }).then(
            response => resolve(response), 
            reason =>  reject(reason)
          );
      });
    };

    onMounted(() => {
      const memberStatusEnum: any = Object.values(model!.enums.MemberStatus).map(e => { return { label: e.Name, value: e.Value } })
      var item = form.formItems[1];          
      if (item.itemRender && memberStatusEnum.length > 0) {            
        form.StatusList = memberStatusEnum; 
        item.itemRender.options = memberStatusEnum;                  
      }

      const memberTypeEnum: any = Object.values(model!.enums.MemberType).map(e => { return { label: e.Name, value: e.Value } })
      var item = form.formItems[11];    
      console.log(memberTypeEnum)      
      if (item.itemRender && memberTypeEnum.length > 0) {            
        form.TypeList = memberTypeEnum; 
        item.itemRender.options = memberTypeEnum;                  
      }

      getClassification().then((data: any) =>{
        item = form.formItems[41]; 
        if (item.itemRender && data.length > 0) {
          item.itemRender.options = data;
        }
      });

      form.CityList = cityList();  
      var citytOption = form.formItems[8];
      if (citytOption.itemRender) citytOption.itemRender.options = form.CityList;
      cityInit();

    })

    const UploadPhotoFiles = (type: any) => {
      var obj = type == 1 ? file1.value : file2.value;
      const formData = new FormData();
      if(obj.length > 0){
        var file = obj[0]['file'];
        var limit = 1024 * 1024 * 25;
        if(file['size'] >= limit) {
          CloudFun.send('error', { subject: '上傳失敗！', content: '檔案大小不得超過25M' })
          return;
        }
        console.log(file['name'])
        if (!/\.(gif|jpg|jpeg|png|webp)$/i.test(file['name'])) {
          CloudFun.send('error', { subject: '上傳失敗！', content: '不支援此檔案類型' })
          return;
        }
        formData.append("files", file);
        model?.dispatch(`member/uploadPicture`, { formData: formData, id: form.formData.Id.toString(), type: type }).then(
          () => {          
            CloudFun.send('info', { subject: '新增成功', content: '新增圖片完成' })
            ReloadFiles(type)
          },
          failure => CloudFun.send('error', { subject: '操作失敗！', content: failure })
        ).finally(() => {

        });
      }
    }

    const ReloadFiles = (type: any) => {
      model?.dispatch('member/find', form.formData.Id).then(
        paylod => {
          if(type == 1 && paylod.ProductPhoto1) { 
            form.formData.ProductPhoto1 = paylod.ProductPhoto1; 
            file1.value = []; 
            if(form.formData.ProductPhoto1.Uri) form.formData.ProductPhoto1.Uri = form.formData.ProductPhoto1.Uri + "?" + new Date().getTime(); ;
          }
          if(type == 2 && paylod.ProductPhoto2) { 
            form.formData.ProductPhoto2 = paylod.ProductPhoto2; 
            file2.value = []; 
            if(form.formData.ProductPhoto2.Uri) form.formData.ProductPhoto2.Uri = form.formData.ProductPhoto2.Uri + "?" + new Date().getTime(); ;
          }
        },
        failure => CloudFun.send('error', { subject: '附件讀取失敗', content: failure.message })
      );
    }

    return {
      UploadPhotoFiles,
      selectdCity,
      form,
      grid,
      gridOptions,
      loading,
      isEditing,
      editingRow,
      isRowSelected,
      keyword,
      customFilters,
      submitSave,
      cityInit,
      changeCity,
      passwordCheck,
      VisibleByType,
      ChangeByType,
      uploader1,
      uploader2,
      file1,
      file2,
      ReloadFiles
    };
  },
  methods: {
    reset() {      
      this.form.formData.Id = "00000000-0000-0000-0000-000000000000";
      this.form.formData.Status = 0;
      this.form.formData.StatusString = '';
      this.form.formData.Account = '';
      this.form.formData.Password = '';
      this.form.formData.Password2 = '';
      this.form.formData.CreatedTimeString = '';
      this.form.formData.CreatedTime = '';
      this.form.formData.Remark = '';
      this.form.formData.Nickname = '';
      this.form.formData.Email = '';
      this.form.formData.JobTitle = '';
      this.form.formData.JobType = '';
      this.form.formData.Unit = '';
      this.form.formData.Education = '';
      this.form.formData.Type = 0;
      this.form.formData.School = '';
      this.form.formData.Person.Id = 0;
      this.form.formData.Person.Fax = '';
      this.form.formData.Person.Sex = 0;
      this.form.formData.Person.BirthDate = '';
      this.form.formData.Person.Phone = '';
      this.form.formData.Person.Address.Id = 0;
      this.form.formData.Person.Address.Line = '';
      this.form.formData.Person.Address.PostalCode = '';
      this.form.formData.Classifications = [];
      this.form.formData.Organization= '';
      this.form.formData.OrganizationEnglish= '';
      this.form.formData.Tax= '';
      this.form.formData.OrgUrl= '';
      this.form.formData.Principal= '';
      this.form.formData.BuildTime= '';
      this.form.formData.Capital= 0;
      this.form.formData.MemberTotal= 0;
      this.form.formData.Representative1= '';
      this.form.formData.Email1= '';
      this.form.formData.JobTitle1= '';
      this.form.formData.Representative2= '';
      this.form.formData.Email2= '';
      this.form.formData.JobTitle2= '';
      this.form.formData.Representative3= '';
      this.form.formData.Email3= '';
      this.form.formData.JobTitle3= '';      
      this.form.formData.Email3= '';
      this.form.formData.JobTitle3= '';
      this.form.formData.Contact= '';
      this.form.formData.ContactEmail= '';
      this.form.formData.ContactJobTitle= '';
      this.form.formData.MainItem= '';
      this.form.formData.Technology= '';
      this.form.formData.Introduction= '';
      if(this.file1.length > 0) this.file1 = []
      if(this.file2.length > 0) this.file2 = []
      this.form.formData.ProductPhoto1 = { Uri:'', Id: 0, }
      this.form.formData.ProductPhoto2 = { Uri:'', Id: 0, }  
      this.cityInit();
      //this.form.formData.Address.City = this.form.CityList[0]['value'];
      //this.form.formData.Address.District = this.form.DistrictsList[0]['value'];       
    },
    edit(row: any) {      
      this.$model.dispatch('member/find', row.Id).then(
        paylod => {
          Object.assign(this.form.formData, paylod)          
          this.form.model = 'edit';
          this.form.formData.Password = '';
          this.form.formData.Password2 = '';
          this.file1 = [];
          this.file2 = [];
          if(this.form.formData.ProductPhoto1.Uri) { 
            setTimeout(() => {
              this.form.formData.ProductPhoto1.Uri = this.form.formData.ProductPhoto1.Uri + "?" + new Date().getTime(); 
            }, 200);
          }
          if(this.form.formData.ProductPhoto2.Uri) { 
            setTimeout(() => {
              this.form.formData.ProductPhoto2.Uri = this.form.formData.ProductPhoto2.Uri + "?" + new Date().getTime(); 
            }, 200);
          }
          this.defaultDistrict(this.form.formData.Person.Address.District);
          this.form.showEdit = true;
          this.passwordCheck(false);
          this.ChangeByType();
        },
        failure => this.$send('error', { subject: '操作失敗！', content: failure.message })
      );
    },
    addNewRow() {
      this.reset();
      this.form.model = 'insert';
      this.passwordCheck(true);  
      this.form.showEdit = true;
      this.ChangeByType();
    },
    save(row?: any) {
      const { insertRecords, updateRecords, removeRecords } = this.grid.getRecordset();
      let needSave = insertRecords.length || updateRecords.length || removeRecords.length;
      needSave &&= row && (insertRecords.some(e => e._XID === row._XID) || updateRecords.some(e => e._XID === row._XID) || removeRecords.some(e => e._XID === row._XID));   
      const isRowEditing = row && this.editingRow === row;
      if (needSave) {
        this.grid.commitProxy('save').then(
          () => {
            if (isRowEditing && this.editingRow === row) this.edit(row);
            else this.editingRow = null;
          }
        );
      } else if (!row || row === this.editingRow) this.editingRow = null;
    },
    remove(row: any) {
      if (row && confirm("確定要刪除此筆資料嗎?")) {
        this.gridOptions.loading = true;
        this.$model.dispatch('member/delete', row.Id).then(
          () => { this.grid.commitProxy('query').finally(() => { this.gridOptions.loading = false; }); },
          failure => this.$send('error', { subject: '會員刪除失敗', content: failure.message })
        );
      }
    },
    removeSelectedRows() {
      cash('#batch-dropdown').dropdown('hide');
      const rows = this.grid.getCheckboxRecords();
      if (rows && rows.length && confirm("確定要刪除已被選擇的資料嗎?")) {
        this.grid.commitProxy('delete');
      }
    },
    changeFilterEvent(event: Event, option: VxeColumnPropTypes.Filter, panel: any, toogleChecked?: boolean) {
      if (toogleChecked) option.checked = !option.checked;
      if (option.checked && option.data) {
        if (!option.value) option.value = new Condition('Name', Operator.Contains);
        option.value.value = option.data;
      } else option.value = option.data = undefined;
      if (toogleChecked || option.checked) panel.changeOption(event, !!option.data, option)
    },
    defaultDistrict(district: string) {      
      this.form.DistrictsList = districtList(this.form.formData.Person.Address.City); 
      var districtOption = this.form.formItems[9];      
      if (districtOption.itemRender) {
        districtOption.itemRender.options = this.form.DistrictsList;        
        this.form.formData.Person.Address.District = district ?? this.form.DistrictsList[0]['value'];
      }
    },
    inputFilter1(newFile: any, oldFile: any, prevent: any) {
      if (newFile && !oldFile) {
        if (!/\.(gif|jpg|jpeg|png|webp)$/i.test(newFile.name)) {
          //this.alert('Your choice is not a picture')
          return prevent()
        }
      }
      if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
        newFile.url = ''
        const URL = window.URL || window.webkitURL        
        if (URL && URL.createObjectURL) {
          newFile.url = URL.createObjectURL(newFile.file)
          this.form.formData.ProductPhoto1.Uri = newFile.url
        }
      }
    },
    inputFilter2(newFile: any, oldFile: any, prevent: any) {
      if (newFile && !oldFile) {
        if (!/\.(gif|jpg|jpeg|png|webp)$/i.test(newFile.name)) {
          //this.alert('Your choice is not a picture')
          return prevent()
        }
      }
      if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
        newFile.url = ''
        const URL = window.URL || window.webkitURL        
        if (URL && URL.createObjectURL) {
          newFile.url = URL.createObjectURL(newFile.file)
          this.form.formData.ProductPhoto2.Uri = newFile.url
        }
      }
    },
    async onExport(type: 'csv' | 'html', mode: 'current' | 'selected' | 'all' = 'all') {
      const config: any = { ...this.gridOptions.exportConfig, type, mode };
      if (mode === 'all') config.data = await this.$model.dispatch('member/query');
      this.grid.exportData({ ...config, type, mode });
    },
    async onPrint(mode: 'current' | 'selected' | 'all' = 'all') {
      const config: any = { ...this.gridOptions.printConfig, mode };
      if (mode === 'all') config.data = await this.$model.dispatch('member/query');
      this.grid.print(config);
    },
    inputFile(newFile: any, oldFile: any, prevent: any) {      
      
      if (newFile && !oldFile) {
        this.$nextTick(function () {          
        })
      }
      if (!newFile && oldFile) {        
      }
    },
    deletePicture(type: any) {
      if((this.form.formData.ProductPhoto1.Id == 0 && type == 1) || (this.form.formData.ProductPhoto2.Id == 0 && type == 2)) {
        if(this.file1.length > 0 || this.file2.length > 0) this.removeFile(type)
        return;
      }
      this.$model.dispatch(`picture/deletePicture`, type == 1 ? this.form.formData.ProductPhoto1.Id : this.form.formData.ProductPhoto2.Id).then(
        paylod => {
          CloudFun.send('info', { subject: '刪除成功！', content: '圖片刪除成功' })
          this.ReloadFiles(type)
        },
        failure => CloudFun.send('error', { subject: '操作失敗！', content: failure})
      ).finally(() => {
      });
    },
    removeFile(type: any) {
      if(this.file1.length > 0 && type == 1){
        this.file1 = [];
        this.form.formData.ProductPhoto1.Uri = '';
      }
      if(this.file2.length > 0 && type == 2){
        this.file2 = [];
        this.form.formData.ProductPhoto2.Uri = '';
      }
    },
  }
});
