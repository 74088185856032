<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">首頁圖片管理</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <button
          v-show="!isEditing"
          class="button text-white bg-theme-1 shadow-md mr-2 flex items-center"
          @click="grid.addNewRow"
        >
          <FontAwesome icon="plus" type="fas" class="w-4 h-4 mr-1" />
          新增
        </button>
        <div
          v-show="!grid.isEditing && grid.isRowSelected"
          id="batch-dropdown"
          class="dropdown ml-auto mr-2 sm:ml-0"
        >
          <button
            class="dropdown-toggle button px-2 box bg-blue-500 text-white flex items-center"
          >
            <FontAwesome icon="tasks" type="fas" class="w-4 h-4 mr-1" />
            群組操作
          </button>
          <div class="dropdown-box">
            <div class="dropdown-box__content box dark:bg-dark-1 p-2 w-32">
              <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.removeSelectedRows"
              >
                <FontAwesome icon="trash" type="fas" class="w-4 h-4 mr-2" />
                刪除
              </span>
              <!-- <span 
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onPrint({ mode: 'selected' })"
              >
                <FontAwesome icon="print" type="fas" class="w-4 h-4 mr-2" /> 列印
              </span> -->
              <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onExport({ type: 'csv', mode: 'selected' })"
              >
                <FontAwesome icon="file-csv" type="fas" class="w-4 h-4 mr-2" />
                匯出 CSV
              </span>
              <!-- <span 
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onExport({ type: 'html', mode: 'selected' })"
              >
                <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" /> 匯出 HTML
              </span> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <div class="xl:flex sm:mr-auto">
          <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
            <input
              type="text"
              class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border"
              v-model.trim="grid.keyword"
              placeholder="請輸入關鍵字"
              @keyup.enter="grid.refresh"
            />
          </div>
          <div class="mt-2 xl:mt-0">
            <button
              type="button"
              class="button w-full sm:w-16 bg-theme-1 text-white"
              @click="grid.refresh"
            >
              搜尋
            </button>
          </div>
        </div>
        <div class="flex mt-2 sm:mt-0">
          <!-- <button
            class="button w-1/2 sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300"
            @click="grid.onPrint({ mode: 'curret' })"
          >
            <FontAwesome icon="print" class="w-4 h-4 mr-2" /> 列印
          </button> -->
          <div class="dropdown w-1/2 sm:w-auto ml-2">
            <button
              class="dropdown-toggle button w-full sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300"
            >
              <FontAwesome icon="file-export" class="w-4 h-4 mr-2" /> 匯出
              <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
            </button>
            <div class="dropdown-box">
              <div class="dropdown-box__content box dark:bg-dark-1 p-2">
                <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="grid.onExport({ type: 'csv' })"
                >
                  <FontAwesome icon="file-csv" class="w-4 h-4 mr-2" /> CSV
                </a>
                <!-- <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="grid.onExport({ type: 'html' })"
                >
                  <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" /> HTML
                </a> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden mt-2">
        <grid
          ref="grid"
          v-bind="gridOptions"
          @addNewRow="
            (row, callback) => {
              row.Published = false;
              row.Ordinal = 0;
              callback();
            }
          "
          @edit="onGridEdit"
          @modalResize="onGridModalResize"
          @removeSelectedRows="onGridRemoveSelectedRows"
        >
          <template #modal="{ row, submit, reset }">
            <vxe-form
              :title-width="60"
              title-align="right"
              v-bind="formOptions"
              :data="row"
              @reset="reset"
              @submit="submit"
            >
              <template #form-banners>
                <div class="ml-3">
                  <slider-editor
                    ref="sliderEditor"
                    :itemWidth="row.Width"
                    :itemHeight="row.Height"
                    :navigation="true"
                    :width="sliderWidth"
                    :filePostAction="`${baseUrl}/api/files`"
                    :formOptions="bannerFormOptions"
                    v-model="row.Banners"
                    @add="onSliderEdit"
                    @edit="onSliderEdit"
                    @save="onSliderSave"
                    @remove="onSliderRemove"
                  />
                </div>
              </template>
            </vxe-form>
          </template>
        </grid>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<style scoped>
.swiper-container {
  padding-top: 5px;
  padding-bottom: 30px;
}
</style>

<script>
import CloudFun, { defineComponent, ref } from "@cloudfun/core";
import Grid from "@/cloudfun/components/Grid.vue";
import SliderEditor from "@/cloudfun/components/SliderEditor.vue";

export default defineComponent({
  components: {
    SliderEditor,
    Grid,
  },
  setup() {
    const model = CloudFun.current?.model;

    const grid = ref({});

    var printColumns = [
      { field: "Code" },
      { field: "Name" },
      { field: "Width" },
      { field: "Height" },
      { field: "Remark" },
    ];

    const gridOptions = {
      title: "首頁圖片位置",
      toolbarConfig: {
        custom: true,
        refresh: true,
      },
      printConfig: {
        sheetName: "首頁圖片清單",
        columns: printColumns,
        modes: ["current", "selected", "all"],
      },
      exportConfig: {
        filename: "首頁圖片清單",
        type: "csv",
        types: ["html", "csv"],
        mode: "all",
        modes: ["current", "selected", "all"],
        columns: printColumns,
      },
      columns: [
        {
          field: "Code",
          title: "代碼",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "text" },
          },
        },
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "text" },
          },
        },
        {
          field: "Width",
          title: "寬度",
          width: 80,
          sortable: true,
        },
        {
          field: "Height",
          title: "高度",
          width: 80,
          sortable: true,
        },
        {
          field: "Remark",
          title: "備註",
          resizable: false,
        },
      ],
      promises: {
        query: model
          ? (params) => model.dispatch("bannerPosition/query", params)
          : undefined,
        queryAll: model
          ? () => model.dispatch("bannerPosition/query")
          : undefined,
        save: model
          ? (params) => model.dispatch("bannerPosition/save", params)
          : undefined,
      },
      modalConfig: { height: 600 },
    };
    const formOptions = {
      items: [
        {
          field: "Code",
          title: "代碼",
          span: 24,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", clearable: true },
          },
        },
        {
          field: "Name",
          title: "名稱",
          span: 24,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", clearable: true },
          },
        },
        {
          field: "Width",
          title: "寬度",
          span: 12,
          itemRender: {
            name: "$input",
            props: {
              placeholder: "請輸入數值",
              type: "number",
              clearable: true,
            },
          },
        },
        {
          field: "Height",
          title: "高度",
          span: 12,
          itemRender: {
            name: "$input",
            props: {
              placeholder: "請輸入數值",
              type: "number",
              clearable: true,
            },
          },
        },
        {
          field: "Remark",
          title: "備註",
          span: 24,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", clearable: true },
          },
        },
        {
          span: 24,
          slots: { default: "form-banners" },
        },
        {
          span: 24,
          align: "right",
          itemRender: {
            name: "$buttons",
            children: [
              { props: { type: "submit", content: "確定", status: "primary" } },
              { props: { type: "reset", content: "重置" } },
            ],
          },
        },
      ],
      rules: {
        Code: [{ type: "string", max: 450, required: true }],
        Name: [{ type: "string", max: 128, required: true }],
        Width: [{ type: "number", min: 1, max: 3840, required: true }],
        Height: [{ type: "number", min: 1, max: 2160, required: true }],
        Remark: [{ type: "string", max: 512 }],
      },
    };

    const sliderWidth = ref("100%");
    const bannerFormOptions = {
      titleWidth: 80,
      titleAlign: "right",
      items: [
        {
          field: "Name",
          title: "名稱",
          span: 16,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", clearable: true },
          },
        },
        {
          field: "Ordinal",
          title: "排序",
          span: 8,
          titleWidth: 60,
          itemRender: {
            name: "$input",
            props: { type: "number", placeholder: "請輸入數字" },
          },
        },
        {
          field: "ClickCount",
          title: "點擊次數",
          span: 24,
          itemRender: {
            name: "$input",
            props: {
              type: "number",
              placeholder: "請輸入數字",
              readonly: true,
            },
          },
        },
        {
          field: "LinkUrl",
          title: "鏈結網址",
          span: 24,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入網址", clearable: true },
          },
        },
        {
          field: "Remark",
          title: "備註",
          span: 24,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", clearable: true },
          },
        },
        {
          span: 24,
          align: "right",
          itemRender: {
            name: "$buttons",
            children: [
              { props: { type: "submit", content: "確定", status: "primary" } },
              { props: { type: "reset", content: "清除" } },
            ],
          },
        },
      ],
      rules: {
        Name: [{ type: "string", max: 128 }],
        LinkUrl: [
          {
            type: "string",
            max: 2000,
            pattern: new RegExp(
              "(https?:\\/\\/[\\w-\\.]+(:\\d+)?(\\/[~\\w\\/\\.]*)?(\\?\\S*)?(#\\S*)?)"
            ),
          },
        ],
        Remark: [{ type: "string", max: 512 }],
      },
    };

    return {
      grid,
      gridOptions,
      formOptions,
      sliderWidth,
      bannerFormOptions,
      baseUrl: process.env.VUE_APP_BACKEND_URL,
    };
  },
  methods: {
    onGridModalResize(size) {
      this.sliderWidth = `${size.width - 100}px`;
    },
    async onGridEdit(row, callback) {
      const entity = row.Id
        ? await this.$model.dispatch("bannerPosition/find", row.Id)
        : undefined;
      if (entity) Object.assign(row, entity);
      if (row.Banners)
        row.Banners = row.Banners.sort((a, b) => a.Ordinal - b.Ordinal);
      callback();
    },
    onGridRemoveSelectedRows(rows, callback) {
      cash("#batch-dropdown").dropdown("hide");
      callback();
    },
    onSliderEdit(image, callback) {
      image.PositionId = this.grid.editingRow.Id;
      callback();
    },
    async onSliderRemove(image, callback) {
      await this.$model.dispatch("banner/delete", image.Id);
      callback();
    },
    async onSliderSave(params, callback) {
      debugger;
      await this.$model.dispatch("banner/save", params).then(
        () => {
          this.$model
            .dispatch("bannerPosition/find", this.grid.editingRow.Id)
            .then(
              (entity) => {
                if (entity.Banners)
                  entity.Banners = entity.Banners.sort(
                    (a, b) => a.Ordinal - b.Ordinal
                  );
                this.$refs.sliderEditor.reload(entity.Banners);
              },
              (reason) => {
                CloudFun.send("error", {
                  subject: "重載失敗",
                  content: reason,
                });
              }
            );
        },
        (reason) => {
          CloudFun.send("error", { subject: "保存失敗", content: reason });
        }
      );
      callback();
    },
  },
});
</script>
