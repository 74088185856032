
import { defineComponent, reactive } from "vue";
import { DxLoadPanel } from "devextreme-vue/load-panel";
import { cloneDeep } from "../../assets/scripts/cloudfun/clone";
export default defineComponent({
  components: {
    DxLoadPanel,
  },
  props: {
    options: {
      type: Object,
    },
  },
  setup(props) {
    const defaultOptions = reactive({
      visible: false,
      showIndicator: true,
      shading: true,
      showPane: true,
      closeOnOutsideClick: false,
    });
    // position: { of: '#employee' },

    const config = cloneDeep({}, defaultOptions, props.options);
    console.log("panel config", config);
    return {
      defaultOptions,
      config,
    };
  },
});
