/* eslint-disable */

import inspectExt from "./inspect";
import { clone, cloneDeep } from "./clone";
import { arrayEqual, looseEqual } from "./loose-equal";
import dateExt from "./date";

const cf = {
    cloneDeep,
    clone,
    checkNestedObject(object, level, ...rest) {
        if (object === undefined) return false;
        if (rest.length == 0 && object.hasOwnProperty(level)) return true;
        return this.checkNestedObject(object[level], ...rest);
    },
    arrayEqual,
    looseEqual,
    inspectExt,
    dateExt
}


export default cf;