import { getPrototypeOf, hasOwnProperty, toString } from './object'

export const isArray = val => val && toString(val) === '[object Array]';
export const isDate = val => val && toString(val) === '[object Date]';
// source == null would test the source is null or undefined
export const isEmpty = val => val == null || val === "" || val === 0 ||
    (isArray(val) && val.length === 0) || (isObject(val) && Object.keys(val).length === 0);
export const isFunction = val => val && toString(val) === '[object Function]';
export const isObject = val => val && toString(val) === '[object Object]';
// To determine the objects are constructed by {} or new Object or Obejct.create(null).
export const isPlainObject = val => {
    if (!val || !isObject(val)) { return false; }

    const proto = getPrototypeOf(val);
    // Objects with no prototype(e.g, Object.create(null)) are plain.
    // Objects are constructed by {} or new Object.
    if (!proto) { return true; }

    // To determine whether Ctor carries with constructor property
    const Ctor = hasOwnProperty(proto, "constructor") && proto.constructor;
    // Ctor instanceof Ctor narrows down to Function and Object
    // Compare constructor and Object
    return typeof Ctor === "function" && Ctor instanceof Ctor &&
        Function.prototype.toString.call(Ctor) === Function.prototype.toString.call(Object);
}
export const isPromise = val => val && toString(val) === '[object Promise]';
export const isValue = (val) => !isArray(val) && !isObject(val);

const inspect = {
    isArray,
    isDate,
    isEmpty,
    isFunction,
    isObject,
    isPlainObject,
    isPromise,
    isValue
}

export default inspect;