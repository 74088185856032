
/* eslint-disable */

import { onMounted } from "vue";
import CloudFun, {
  Model,
  defineComponent,
  ref,
  reactive,
  computed,
  Sorting,
  SortOrder,
  Condition,
  Operator,
  LogicalConnective,
} from "@cloudfun/core";
import {
  VxeColumnPropTypes,
  VxeGridInstance,
  VxeGridProps,
  VxeFormPropTypes,
  VxeModalInstance,
  VxeFormInstance,
} from "vxe-table";
import FileUpload from "vue-upload-component";
import formatDate from "xe-utils/toDateString";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import "@/global-components/ckeditor/styles.css";

export default defineComponent({
  components: {
    FileUpload,
    Loading,
  },
  setup() {
    const modal = ref({} as VxeModalInstance);
    const model = CloudFun.current?.model;
    const grid = ref({} as VxeGridInstance);
    const gridCartItem = ref({} as VxeGridInstance);
    const vxeForm = ref({} as VxeFormInstance);
    const loading = ref(false);
    const editingRow = ref(null);
    const keyword = ref("");
    const customFilters = ref<Condition[]>([]);
    const isRowSelected = computed(
      () =>
        (grid.value.getRadioRecord && grid.value.getRadioRecord()) ||
        (grid.value.getCheckboxRecords &&
          grid.value.getCheckboxRecords().length > 0)
    );
    const isEditing = computed(
      () =>
        editingRow.value != null && grid.value.isActiveByRow(editingRow.value)
    );
    var load = reactive({
      isLoading: false,
      fullPage: false,
    });
    var printColumns = [{ field: "Number" }];

    const gridOptions = reactive({
      keepSource: true,
      round: true,
      border: true,
      stripe: true,
      highlightHoverColumn: true,
      highlightHoverRow: true,
      resizable: true,
      loading: false,
      customConfig: { storage: true },
      filterConfig: { remote: true },
      sortConfig: { remote: true, multiple: true },
      //editConfig: { trigger: 'manual', mode: 'row', showIcon: false, autoClear: false },
      pagerConfig: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [5, 10, 20],
        layouts: [
          "PrevJump",
          "PrevPage",
          "Jump",
          "PageCount",
          "NextPage",
          "NextJump",
          "Sizes",
          "Total",
        ],
      },
      printConfig: {
        sheetName: "購物車清單",
        columns: printColumns,
        modes: ["current", "selected", "all"],
      },
      exportConfig: {
        filename: "購物車清單",
        type: "csv",
        types: ["html", "csv"],
        mode: "all",
        modes: ["current", "selected", "all"],
        columns: printColumns,
      },
      columns: [
        {
          field: "CreatedTime",
          title: "時間",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
        },
        {
          field: "Store.Organization.Name",
          title: "經銷商",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
        },
        {
          field: "SaleAmount",
          title: "原價總額",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
        },
        {
          field: "Discount",
          title: "折扣金額",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
        },
        {
          field: "Amount",
          title: "訂單淨額",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
        },
        {
          title: "操作",
          width: 100,
          fixed: "right",
          align: "center",
          resizable: false,
          slots: { default: "operate" },
        },
      ],
      proxyConfig: {
        message: false,
        sort: true,
        filter: true,
        props: { result: "data", total: "totalCount" },
        ajax: {
          query: (params) => {
            //params.sorts.push({ column: Column.prototype, order: 'desc', sortBy: 'Number', property:'Number' });
            //console.log(params)
            const queryParams: {
              page: number;
              pageSize: number;
              keyword: string;
              sortings?: Sorting[];
              condition: Condition;
            } = {
              page: params.page.currentPage,
              pageSize: params.page.pageSize,
              keyword: keyword.value,
              sortings: params.sorts
                .filter((e) => e.property)
                .map(
                  (e) =>
                    new Sorting(
                      e.property,
                      e.order === "desc"
                        ? SortOrder.Descending
                        : SortOrder.Ascending
                    )
                ), // sorts
              condition: new Condition(customFilters.value), // custom filters
            };
            //console.log(queryParams)
            // filters
            const filters = params.filters
              ? params.filters.filter((e) => e.values.length)
              : null;
            if (filters && filters.length) {
              const gridCondition = new Condition();
              // colum filters
              filters.forEach((filter) => {
                const columnCondition = new Condition();
                filter.values.forEach((subFilter) => {
                  if (subFilter && subFilter instanceof Condition) {
                    const condition = subFilter as Condition;
                    if (condition.connective === LogicalConnective.And)
                      columnCondition.and(condition);
                    else columnCondition.or(condition);
                  }
                });
                gridCondition.and(columnCondition);
              });
              queryParams.condition.and(gridCondition);
            }
            return new Promise((resolve, reject) =>
              model?.dispatch("shoppingCart/load", queryParams).then(
                (payload) => {
                  resolve(payload);
                },
                (reason) => {
                  CloudFun.send("error", {
                    subject: "讀取失敗",
                    content: reason,
                  });
                  reject(reason);
                }
              )
            );
          },
          delete: (params) => {
            const deleteParams = { deleteRows: params.body.removeRecords };
            return new Promise((resolve, reject) =>
              model?.dispatch("shoppingCart/save", deleteParams).then(
                (payload) => resolve(payload),
                (reason) => {
                  CloudFun.send("error", {
                    subject: "刪除失敗",
                    content: reason,
                  });
                  reject(reason);
                }
              )
            );
          },
          save: (params) => {
            const saveParams = {
              insertRows: params.body.insertRecords,
              updateRows: params.body.updateRecords,
              deleteRows: params.body.removeRecords,
            };
            return new Promise((resolve, reject) =>
              model?.dispatch("shoppingCart/save", saveParams).then(
                (payload) => {
                  editingRow.value = null;
                  resolve(payload);
                },
                (reason) => {
                  CloudFun.send("error", {
                    subject: "保存失敗",
                    content: reason,
                  });
                  reject(reason);
                }
              )
            );
          },
        },
      },
    } as VxeGridProps);

    const cartItemOptions = reactive({
      keepSource: true,
      round: true,
      border: true,
      stripe: true,
      highlightHoverColumn: true,
      highlightHoverRow: true,
      resizable: true,
      loading: false,
      customConfig: { storage: false },
      filterConfig: { remote: false },
      sortConfig: { remote: true, multiple: true },
      //editConfig: { trigger: 'manual', mode: 'row', showIcon: false, autoClear: false },
      pagerConfig: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [5, 10, 20],
        layouts: [
          "PrevJump",
          "PrevPage",
          "Jump",
          "PageCount",
          "NextPage",
          "NextJump",
          "Sizes",
          "Total",
        ],
      },
      printConfig: {
        sheetName: "購物車清單",
        columns: printColumns,
        modes: ["current", "selected", "all"],
      },
      exportConfig: {
        filename: "購物車清單",
        type: "csv",
        types: ["html", "csv"],
        mode: "all",
        modes: ["current", "selected", "all"],
        columns: printColumns,
      },
      columns: [
        {
          field: "ProductItemSize.ProductItem.Product.Name",
          title: "產品名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
        },
        {
          field: "ProductItemSize.ProductItem.Color.Name",
          title: "顏色",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
        },
        {
          field: "ProductItemSize.Size.Name",
          title: "尺寸",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
        },
        {
          field: "Price",
          title: "原價",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
        },
        {
          field: "Discount",
          title: "折扣金額",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
        },
        {
          field: "Quantity",
          title: "數量",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
        },
        {
          field: "Amount",
          title: "購物車淨額",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
        },
        {
          field: "PreOrderYear",
          title: "預購年分",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
        },
        {
          field: "PreOrderMonth",
          title: "預購月份",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
        },
      ],
      proxyConfig: {
        message: false,
        sort: true,
        filter: true,
        props: { result: "data", total: "totalCount" },
        ajax: {
          query: (params) => {
            //params.sorts.push({ column: Column.prototype, order: 'desc', sortBy: 'Number', property:'Number' });
            const queryParams: {
              page: number;
              pageSize: number;
              keyword: string;
              sortings?: Sorting[];
              condition: Condition;
              id?: number;
            } = {
              page: params.page.currentPage,
              pageSize: params.page.pageSize,
              keyword: keyword.value,
              id: form.formData.Id,
              sortings: params.sorts
                .filter((e) => e.property)
                .map(
                  (e) =>
                    new Sorting(
                      e.property,
                      e.order === "desc"
                        ? SortOrder.Descending
                        : SortOrder.Ascending
                    )
                ), // sorts
              condition: new Condition(customFilters.value), // custom filters
            };
            queryParams.sortings?.push(
              new Sorting("CreatedTime", SortOrder.Descending)
            );
            //console.log(queryParams)
            // filters
            const filters = params.filters
              ? params.filters.filter((e) => e.values.length)
              : null;
            if (filters && filters.length) {
              const gridCondition = new Condition();
              // colum filters
              filters.forEach((filter) => {
                const columnCondition = new Condition();
                filter.values.forEach((subFilter) => {
                  if (subFilter && subFilter instanceof Condition) {
                    const condition = subFilter as Condition;
                    if (condition.connective === LogicalConnective.And)
                      columnCondition.and(condition);
                    else columnCondition.or(condition);
                  }
                });
                gridCondition.and(columnCondition);
              });
              queryParams.condition.and(gridCondition);
            }
            return new Promise((resolve, reject) =>
              model?.dispatch("shoppingCartItem/load", queryParams).then(
                (payload) => {
                  resolve(payload);
                },
                (reason) => {
                  CloudFun.send("error", {
                    subject: "讀取失敗",
                    content: reason,
                  });
                  reject(reason);
                }
              )
            );
          },
          delete: (params) => {
            const deleteParams = { deleteRows: params.body.removeRecords };
            return new Promise((resolve, reject) =>
              model?.dispatch("shoppingCartItem/save", deleteParams).then(
                (payload) => resolve(payload),
                (reason) => {
                  CloudFun.send("error", {
                    subject: "刪除失敗",
                    content: reason,
                  });
                  reject(reason);
                }
              )
            );
          },
          save: (params) => {
            const saveParams = {
              insertRows: params.body.insertRecords,
              updateRows: params.body.updateRecords,
              deleteRows: params.body.removeRecords,
            };
            return new Promise((resolve, reject) =>
              model?.dispatch("shoppingCartItem/save", saveParams).then(
                (payload) => {
                  editingRow.value = null;
                  resolve(payload);
                },
                (reason) => {
                  CloudFun.send("error", {
                    subject: "保存失敗",
                    content: reason,
                  });
                  reject(reason);
                }
              )
            );
          },
        },
      },
    } as VxeGridProps);

    //modal 執行 新增or修改
    const submitSave = () => {
      vxeForm.value.validate().then(
        () => {
          var method = "update";
          gridOptions.loading = true;
          load.isLoading = true;
          model
            ?.dispatch(`shoppingCart/${method}`, form.formData)
            .then(
              (paylod) => {
                //var sorting = new Sorting('CreatedTime', 1);
                grid.value.commitProxy("query").finally(() => {});
                CloudFun.send("info", {
                  subject: method == "insert" ? "新增成功" : "更新成功",
                  content:
                    method == "insert" ? "購物車新增完成" : "購物車更新完成",
                });
              },
              (failure) =>
                CloudFun.send("error", {
                  subject: "操作失敗！",
                  content: failure,
                })
            )
            .finally(() => {
              //form.showEdit = false;
              gridOptions.loading = false;
              load.isLoading = false;
            });
        },
        (failure) => null
      );
    };

    //modal 執行 新增or修改
    const resetButton = () => {
      model?.dispatch("shoppingCart/find", form.formData.Id).then(
        (paylod) => {
          Object.assign(form.formData, paylod);
        },
        (failure) =>
          CloudFun.send("error", {
            subject: "操作失敗！",
            content: failure.message,
          })
      );
    };

    const form = reactive({
      model: "insert",
      CityList: [],
      DistrictsList: [],
      CategoryList: [],
      selectRow: true,
      showEdit: false,
      formData: {
        Id: 0,
        Number: "",
        SaleAmount: 0,
        Discount: 0,
        Amount: 0,
        Time: "",
        OrderItems: [],
      },
      orderItemData: {},
      formRules: {
        Number: [{ required: true, message: "請輸入內容" }],
        "Store.Organization.Name": [{ required: true, message: "請輸入內容" }],
        Type: [{ required: true, message: "請輸入內容" }],
        Status: [{ required: true, message: "請輸入內容" }],
        SaleAmount: [{ required: true, message: "請輸入內容" }],
        Discount: [{ required: true, message: "請輸入內容" }],
        Amount: [{ required: true, message: "請輸入內容" }],
        Time: [{ required: true, message: "請輸入內容" }],
      } as VxeFormPropTypes.Rules,
      formItems: [
        {
          field: "CreatedTime",
          title: "時間",
          span: 12,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", readonly: true },
          },
        },
        {
          field: "Store.Organization.Name",
          title: "經銷商",
          span: 12,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", readonly: true },
          },
        },
        {
          field: "SaleAmount",
          title: "原價總額",
          span: 12,
          itemRender: {
            name: "$input",
            props: { type: "float", placeholder: "請輸入", readonly: true },
          },
        },
        {
          field: "Discount",
          title: "折扣金額",
          span: 12,
          itemRender: {
            name: "$input",
            props: { type: "float", placeholder: "請輸入", readonly: true },
          },
        },
        {
          field: "Amount",
          title: "訂單淨額",
          span: 12,
          itemRender: {
            name: "$input",
            props: { type: "float", placeholder: "請輸入", readonly: true },
          },
        },
        { span: 24, slots: { default: "item-list" } },
        // {
        //   align: "right",
        //   span: 24,
        //   titleAlign: "left",
        //   itemRender: {
        //     name: "$buttons",
        //     children: [
        //       {
        //         props: { type: "submit", content: "確認", status: "primary" },
        //         events: { click: submitSave },
        //       },
        //       { props: { type: "reset", content: "重置" } },
        //     ],
        //   },
        //}, //, events: { click: save }
      ] as VxeFormPropTypes.Items,
    });

    onMounted(() => {
      const orderStatusEnum: any = Object.values(model!.enums.OrderStatus).map(
        (e) => {
          return { label: e.Name, value: e.Value };
        }
      );
      var item = form.formItems[3];
      if (item.itemRender && orderStatusEnum.length > 0) {
        item.itemRender.options = orderStatusEnum;
      }
    });

    const onResize = () => {};

    return {
      load,
      form,
      grid,
      gridCartItem,
      gridOptions,
      cartItemOptions,
      loading,
      isEditing,
      editingRow,
      isRowSelected,
      keyword,
      customFilters,
      submitSave,
      modal,
      onResize,
      resetButton,
      vxeForm,
    };
  },
  methods: {
    reset() {
      this.form.formData.Id = 0;
      this.form.formData.Number = "";
      this.form.formData.SaleAmount = 0;
      this.form.formData.Amount = 0;
      this.form.formData.Discount = 0;
      this.form.formData.Time = "";
      this.form.formData.OrderItems = [];
    },
    edit(row: any) {
      this.$model.dispatch("shoppingCart/find", row.Id).then(
        (paylod) => {
          Object.assign(this.form.formData, paylod);
          this.form.model = "edit";
          this.form.showEdit = true;
          if (this.gridCartItem.keepSource)
            this.gridCartItem.commitProxy("query");
        },
        (failure) =>
          this.$send("error", {
            subject: "操作失敗！",
            content: failure.message,
          })
      );
    },
    addNewRow() {
      this.reset();
      this.form.model = "insert";
      this.form.showEdit = true;
    },
    save(row?: any) {
      const {
        insertRecords,
        updateRecords,
        removeRecords,
      } = this.grid.getRecordset();
      let needSave =
        insertRecords.length || updateRecords.length || removeRecords.length;
      needSave &&=
        row &&
        (insertRecords.some((e) => e._XID === row._XID) ||
          updateRecords.some((e) => e._XID === row._XID) ||
          removeRecords.some((e) => e._XID === row._XID));
      const isRowEditing = row && this.editingRow === row;
      if (needSave) {
        this.grid.commitProxy("save").then(() => {
          if (isRowEditing && this.editingRow === row) this.edit(row);
          else this.editingRow = null;
        });
      } else if (!row || row === this.editingRow) this.editingRow = null;
    },
    remove(row: any) {
      if (row && confirm("確定要刪除此筆資料嗎?")) {
        this.gridOptions.loading = true;
        this.$model.dispatch("shoppingCart/delete", row.Id).then(
          () => {
            this.grid.commitProxy("query").finally(() => {
              this.gridOptions.loading = false;
            });
          },
          (failure) =>
            this.$send("error", {
              subject: "購物車刪除失敗",
              content: failure.message,
            })
        );
      }
    },
    removeSelectedRows() {
      cash("#batch-dropdown").dropdown("hide");
      const rows = this.grid.getCheckboxRecords();
      if (rows && rows.length && confirm("確定要刪除已被選擇的資料嗎?")) {
        this.grid.commitProxy("delete");
      }
    },
    changeFilterEvent(
      event: Event,
      option: VxeColumnPropTypes.Filter,
      panel: any,
      toogleChecked?: boolean
    ) {
      if (toogleChecked) option.checked = !option.checked;
      if (option.checked && option.data) {
        if (!option.value)
          option.value = new Condition("Name", Operator.Contains);
        option.value.value = option.data;
      } else option.value = option.data = undefined;
      if (toogleChecked || option.checked)
        panel.changeOption(event, !!option.data, option);
    },
    async onExport(
      type: "csv" | "html",
      mode: "current" | "selected" | "all" = "all"
    ) {
      const config: any = { ...this.gridOptions.exportConfig, type, mode };
      if (mode === "all")
        config.data = await this.$model.dispatch("shoppingCart/query");
      console.log(config);
      this.grid.exportData({ ...config, type, mode });
    },
    async onPrint(mode: "current" | "selected" | "all" = "all") {
      const config: any = { ...this.gridOptions.printConfig, mode };
      if (mode === "all")
        config.data = await this.$model.dispatch("shoppingCart/query");
      this.grid.print(config);
    },
  },
});
