/* Fromat: {  culture-code: localization-json } */
// export default {
//     "zh-Hant": require('./zh-Hant.json'),
//     "en-US": require('./en-US.json')
// }

import { createI18n } from "vue-i18n";

import "devextreme-intl";
import { locale, loadMessages } from "devextreme/localization";
import config from "devextreme/core/config";
import messages from "./devextreme/zh-tw.json";

loadMessages(messages);
locale("zh-tw");
config({ defaultCurrency: "TWD" });

const defaultLocale = "zh-Hant";
const i18n = createI18n({
  locale: defaultLocale,
  fallbackLocale: defaultLocale,
  messages: {
    "zh-Hant": require("./zh-Hant.json"),
    "en-US": require("./en-US.json"),
  },
});

export default i18n;
