import ArrayStore from "devextreme/data/array_store";
import LocalStore from "devextreme/data/local_store";
import DataSource from "devextreme/data/data_source";
import createStore from "../..utils/dx-custom-store";
import createDataSource from "@/utils/devextreme";
import CloudFun, { IModel } from "@cloudfun/core";

/* eslint-disable */
const DxCustomStore = (options: {
  key: string | string[];
  loadAction: string;
  insertAction?: string;
  updateAction?: string;
  deleteAction?: string;
}) => {
  const model: IModel = CloudFun.current!.model;
  return createDataSource(
    model,
    options.key,
    options.loadAction,
    options.insertAction,
    options.updateAction,
    options.deleteAction
  );
};

const CustomStore = (apiUrl: string) => {
  const options: {
    key: string | string[];
    loadAction: string;
    insertAction?: string;
    updateAction?: string;
    deleteAction?: string;
  } = {
    key: "Id",
    loadAction: `${apiUrl}/query`,
    insertAction: `${apiUrl}/save`,
    updateAction: `${apiUrl}/save`,
    deleteAction: `${apiUrl}/remove`,
  };
  return DxCustomStore(options);
};

const DxDataSource = (dataStore: any, options: any) => {
  const result = {
    dataSource: new DataSource({
      store: dataStore,
      select: options.select,
      filter: options.filterExpr, // [["column", "operator(<、>、=...)", "value"], "and || or", []  ]
      sort: options.sortExpr, // string、object、array、function
    }),
    find: options.find,
    insert: options.insert,
    update: options.update,
  };

  result.find = function (key: any) {
    // store.dispatch("IS_LOADING", true, { root: true });
    return new Promise((resolve, reject) => {
      result.dataSource
        .store()
        .byKey(key)
        .done((data: any) => {
          // store.dispatch("IS_LOADING", false, { root: true });
          resolve(data);
        })
        .fail((err: any) => {
          // store.dispatch("IS_LOADING", false, { root: true });
          reject(err);
        });
    });
  };

  result.insert = function (values: any) {
    //   store.dispatch("IS_LOADING", true, { root: true });
    return new Promise((resolve, reject) => {
      result.dataSource
        .store()
        .insert(values)
        .done((data: any) => {
          result.dataSource.reload();
          // store.dispatch("IS_LOADING", false, { root: true });
          resolve(data);
        })
        .fail((err: any) => {
          // store.dispatch("IS_LOADING", false, { root: true });
          reject(err);
        });
    });
  };

  result.update = function (key: any, values: any) {
    //   store.dispatch("IS_LOADING", true, { root: true });
    return new Promise((resolve, reject) => {
      result.dataSource
        .store()
        .update(key, values)
        .done((data: any) => {
          result.dataSource.reload();
          // store.dispatch("IS_LOADING", false, { root: true });
          resolve(data);
        })
        .fail((err: any) => {
          // store.dispatch("IS_LOADING", false, { root: true });
          reject(err);
        });
    });
  };

  return result;
};

const generateDataSourceConfiguration = (store: any, options: any) => {
  const selectExpr = options.selectExpr || ["Id", "Name"];
  const pager = options.pager || { enable: true, pageSize: 10 };
  return {
    store: store,
    select: selectExpr,
    filter: options.filterExpr,
    paginate: pager.enable,
    pageSize: pager.pageSize,
  };
};

/* Data Store */

const CountryStore = () => {
  return CustomStore("country");
};

const WarehouseStore = () => {
  return CustomStore("warehouse");
};

const ProductCategoryStore = () => {
  return CustomStore("productCategory");
};
const ErpProductCategoryStore = () => {
  return CustomStore("erpProductCategory");
};

const ErpProductSubCategoryStore = () => {
  return CustomStore("erpProductSubCategory");
};

const TagStore = () => {
  return CustomStore("tag");
};

const CategoryStore = () => {
  return CustomStore("category");
};

const ColorStore = () => {
  return CustomStore("color");
};

const SizeStore = () => {
  return CustomStore("size");
};

const ShoppingCartStore = () => {
  return CustomStore("shoppingCart");
};

const ShoppingCartItemStore = () => {
  return CustomStore("shoppingCartItem");
};

const OrderStore = () => {
  return CustomStore("order");
};

const OrderItemStore = () => {
  return CustomStore("orderItem");
};

const MaterialTagStore = () => {
  return CustomStore("materialTag");
};

/* Data Configuration */

const CountryDataConfiguration = (options = {}) => {
  return generateDataSourceConfiguration(CountryStore(), options);
};

const WarehouseDataConfiguration = (options = {}) => {
  return generateDataSourceConfiguration(WarehouseStore(), options);
};

const ProductCategoryDataConfiguration = (options = {}) => {
  return generateDataSourceConfiguration(ProductCategoryStore(), options);
};

const ErpProductCategoryDataConfiguration = (options = {}) => {
  return generateDataSourceConfiguration(ErpProductCategoryStore(), options);
};
const ErpProductSubCategoryDataConfiguration = (options = {}) => {
  return generateDataSourceConfiguration(ErpProductSubCategoryStore(), options);
};

const TagDataConfiguration = (options = {}) => {
  return generateDataSourceConfiguration(TagStore(), options);
};

const CategoryDataConfiguration = (options = {}) => {
  return generateDataSourceConfiguration(CategoryStore(), options);
};

const ColorDataConfiguration = (options = {}) => {
  return generateDataSourceConfiguration(ColorStore(), options);
};

const SizeDataConfiguration = (options = {}) => {
  return generateDataSourceConfiguration(SizeStore(), options);
};

const ShoppingCartDataConfiguration = (options = {}) => {
  return generateDataSourceConfiguration(ShoppingCartStore(), options);
};

const ShoppingCartItemDataConfiguration = (options = {}) => {
  return generateDataSourceConfiguration(ShoppingCartItemStore(), options);
};

const OrderDataConfiguration = (options = {}) => {
  return generateDataSourceConfiguration(OrderStore(), options);
};

const OrderItemDataConfiguration = (options = {}) => {
  return generateDataSourceConfiguration(OrderItemStore(), options);
};

const MaterialTagDataConfiguration = (options = {}) => {
  return generateDataSourceConfiguration(MaterialTagStore(), options);
};

/* Data Source */

const CountryDataSource = (options = {}) => {
  return DxDataSource(CountryStore(), options);
};

const WarehouseDataSource = (options = {}) => {
  return DxDataSource(WarehouseStore(), options);
};

const ProductCategoryDataSource = (options = {}) => {
  return DxDataSource(ProductCategoryStore(), options);
};

const ErpProductCategoryDataSource = (options = {}) => {
  return DxDataSource(ErpProductCategoryStore(), options);
};
const ErpProductSubCategoryDataSource = (options = {}) => {
  return DxDataSource(ErpProductSubCategoryStore(), options);
};

const TagDataSource = (options = {}) => {
  return DxDataSource(TagStore(), options);
};

const CategoryDataSource = (options = {}) => {
  return DxDataSource(CategoryStore(), options);
};
const ColorDataSource = (options = {}) => {
  return DxDataSource(ColorStore(), options);
};
const SizeDataSource = (options = {}) => {
  return DxDataSource(SizeStore(), options);
};

const ShoppingCartDataSource = (options = {}) => {
  return DxDataSource(ShoppingCartStore(), options);
};
const ShoppingCartItemDataSource = (options = {}) => {
  return DxDataSource(ShoppingCartItemStore(), options);
};

const OrderDataSource = (options = {}) => {
  return DxDataSource(OrderStore(), options);
};

const OrderItemDataSource = (options = {}) => {
  return DxDataSource(OrderItemStore(), options);
};

const MaterialTagDataSource = (options = {}) => {
  return DxDataSource(MaterialTagStore(), options);
};

const DxStores = {
  CountryStore,
  WarehouseStore,
  ProductCategoryStore,
  ErpProductCategoryStore,
  ErpProductSubCategoryStore,
  TagStore,
  CategoryStore,
  ColorStore,
  SizeStore,
  ShoppingCartStore,
  ShoppingCartItemStore,
  OrderStore,
  OrderItemStore,
  MaterialTagStore,
};

const DxDataConfigurations = {
  CountryDataConfiguration,
  WarehouseDataConfiguration,
  ProductCategoryDataConfiguration,
  ErpProductCategoryDataConfiguration,
  ErpProductSubCategoryDataConfiguration,
  TagDataConfiguration,
  CategoryDataConfiguration,
  ColorDataConfiguration,
  SizeDataConfiguration,
  ShoppingCartDataConfiguration,
  ShoppingCartItemDataConfiguration,
  OrderDataConfiguration,
  OrderItemDataConfiguration,
  MaterialTagDataConfiguration,
};

const DxDataSources = {
  CountryDataSource,
  WarehouseDataSource,
  ProductCategoryDataSource,
  ErpProductCategoryDataSource,
  ErpProductSubCategoryDataSource,
  TagDataSource,
  CategoryDataSource,
  ColorDataSource,
  SizeDataSource,
  ShoppingCartDataSource,
  ShoppingCartItemDataSource,
  OrderDataSource,
  OrderItemDataSource,
  MaterialTagDataSource,
};

export { DxStores, DxDataSources, DxDataConfigurations };
