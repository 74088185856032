
/* eslint-disable */

import { onMounted } from "vue";
import CloudFun, {
  defineComponent,
  ref,
  reactive,
  computed,
  Sorting,
  SortOrder,
  Condition,
  Operator,
  LogicalConnective,
} from "@cloudfun/core";
import { method } from "node_modules/@types/lodash";
import {
  VxeColumnPropTypes,
  VxeGridInstance,
  VxeGridProps,
  VxeFormPropTypes,
  Column,
} from "vxe-table";

export default defineComponent({
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref({} as VxeGridInstance);
    const loading = ref(false);
    const editingRow = ref(null);
    const keyword = ref("");
    const customFilters = ref<Condition[]>([]);
    const isRowSelected = computed(
      () =>
        (grid.value.getRadioRecord && grid.value.getRadioRecord()) ||
        (grid.value.getCheckboxRecords &&
          grid.value.getCheckboxRecords().length > 0)
    );
    const isEditing = computed(
      () =>
        editingRow.value != null && grid.value.isActiveByRow(editingRow.value)
    );
    var printColumns = [
      { field: "CreatedTimeString" },
      { field: "Name" },
      { field: "PublishedString" },
      { field: "Ordinal" },
    ];

    const gridOptions = reactive({
      keepSource: true,
      round: true,
      border: true,
      stripe: true,
      highlightHoverColumn: true,
      highlightHoverRow: true,
      resizable: true,
      loading: false,
      customConfig: { storage: true },
      filterConfig: { remote: true },
      sortConfig: { remote: true, multiple: true },
      //editConfig: { trigger: 'manual', mode: 'row', showIcon: false, autoClear: false },
      pagerConfig: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [5, 10, 20],
        layouts: [
          "PrevJump",
          "PrevPage",
          "Jump",
          "PageCount",
          "NextPage",
          "NextJump",
          "Sizes",
          "Total",
        ],
      },
      printConfig: {
        sheetName: "相關連結分類清單",
        columns: printColumns,
        modes: ["current", "selected", "all"],
      },
      exportConfig: {
        filename: "相關連結分類清單",
        type: "csv",
        types: ["html", "csv"],
        mode: "all",
        modes: ["current", "selected", "all"],
        columns: printColumns,
      },
      columns: [
        { type: "checkbox", width: 35, fixed: "left", resizable: false },
        { field: "CreatedTimeString", title: "建立時間" },
        {
          field: "Name",
          title: "分類名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          filters: [{ checked: false, label: "包含" }],
          slots: { filter: "name_filter" },
        },
        {
          field: "PublishedString",
          title: "發佈",
          showHeaderOverflow: true,
          showOverflow: true,
          resizable: false,
        },
        {
          field: "Ordinal",
          title: "排序",
          showHeaderOverflow: true,
          showOverflow: true,
          resizable: false,
        },
        {
          title: "操作",
          width: 100,
          fixed: "right",
          align: "center",
          resizable: false,
          slots: { default: "operate" },
        },
      ],
      proxyConfig: {
        message: false,
        sort: true,
        filter: true,
        props: { result: "data", total: "totalCount" },
        ajax: {
          query: (params) => {
            //params.sorts.push({ column: Column.prototype, order: 'desc', sortBy: 'Number', property:'Number' });
            //console.log(params)
            const queryParams: {
              page: number;
              pageSize: number;
              keyword: string;
              sortings?: Sorting[];
              condition: Condition;
            } = {
              page: params.page.currentPage,
              pageSize: params.page.pageSize,
              keyword: keyword.value,
              sortings: params.sorts
                .filter((e) => e.property)
                .map(
                  (e) =>
                    new Sorting(
                      e.property,
                      e.order === "desc"
                        ? SortOrder.Descending
                        : SortOrder.Ascending
                    )
                ), // sorts
              condition: new Condition(customFilters.value), // custom filters
            };
            //console.log(queryParams)
            // filters
            const filters = params.filters
              ? params.filters.filter((e) => e.values.length)
              : null;
            if (filters && filters.length) {
              const gridCondition = new Condition();
              // colum filters
              filters.forEach((filter) => {
                const columnCondition = new Condition();
                filter.values.forEach((subFilter) => {
                  if (subFilter && subFilter instanceof Condition) {
                    const condition = subFilter as Condition;
                    if (condition.connective === LogicalConnective.And)
                      columnCondition.and(condition);
                    else columnCondition.or(condition);
                  }
                });
                gridCondition.and(columnCondition);
              });
              queryParams.condition.and(gridCondition);
            }
            return new Promise((resolve, reject) =>
              model?.dispatch("linkCategory/load", queryParams).then(
                (payload) => {
                  resolve(payload);
                },
                (reason) => {
                  CloudFun.send("error", {
                    subject: "讀取失敗",
                    content: reason,
                  });
                  reject(reason);
                }
              )
            );
          },
          delete: (params) => {
            const deleteParams = { deleteRows: params.body.removeRecords };
            return new Promise((resolve, reject) =>
              model?.dispatch("linkCategory/save", deleteParams).then(
                (payload) => resolve(payload),
                (reason) => {
                  CloudFun.send("error", {
                    subject: "刪除失敗",
                    content: reason,
                  });
                  reject(reason);
                }
              )
            );
          },
          save: (params) => {
            const saveParams = {
              insertRows: params.body.insertRecords,
              updateRows: params.body.updateRecords,
              deleteRows: params.body.removeRecords,
            };
            return new Promise((resolve, reject) =>
              model?.dispatch("linkCategory/save", saveParams).then(
                (payload) => {
                  editingRow.value = null;
                  resolve(payload);
                },
                (reason) => {
                  CloudFun.send("error", {
                    subject: "保存失敗",
                    content: reason,
                  });
                  reject(reason);
                }
              )
            );
          },
        },
      },
    } as VxeGridProps);

    //modal 執行 新增or修改
    const submitSave = () => {
      var method = form.model == "insert" ? "insert" : "update";
      gridOptions.loading = true;
      //console.log(form.model, method)
      model
        ?.dispatch(`linkCategory/${method}`, form.formData)
        .then(
          (paylod) => {
            //var sorting = new Sorting('CreatedTime', 1);
            grid.value.commitProxy("query");
            CloudFun.send("info", {
              subject: method == "insert" ? "新增成功" : "更新成功",
              content:
                method == "insert"
                  ? "相關連結分類新增完成"
                  : "相關連結分類更新完成",
            });
          },
          (failure) =>
            CloudFun.send("error", { subject: "操作失敗！", content: failure })
        )
        .finally(() => {
          form.showEdit = false;
          gridOptions.loading = false;
        });
    };

    const form = reactive({
      model: "insert",
      CityList: [],
      DistrictsList: [],
      selectRow: true,
      showEdit: false,
      formData: {
        Id: 0,
        CreatedTimeString: "",
        CreatedTime: "",
        Name: "",
        PublishedString: "",
        Ordinal: 0,
        SecretaryGeneral: "",
        Published: false,
        Remark: "",
      },
      formRules: {
        Name: [{ required: true, message: "請輸入分類名稱" }],
        Number: [{ required: true, message: "請輸入編號" }],
        Ordinal: [{ required: true, message: "請輸入排序" }],
        Published: [{ required: true, message: "請輸入發佈" }],
      } as VxeFormPropTypes.Rules,
      formItems: [
        {
          field: "CreatedTimeString",
          title: "建立時間",
          span: 12,
          itemRender: {
            name: "$input",
            props: { placeholder: "", disabled: "true" },
          },
        },
        {
          field: "Name",
          title: "分類名稱",
          span: 12,
          itemRender: { name: "$input", props: { placeholder: "分類名稱" } },
        },
        {
          field: "Ordinal",
          title: "排序",
          span: 12,
          itemRender: {
            name: "$input",
            props: { type: "number", placeholder: "排序" },
          },
        },
        {
          field: "Published",
          title: "發佈",
          span: 12,
          itemRender: {
            name: "$radio",
            options: [
              { label: "是", value: true },
              { label: "否", value: false },
            ],
          },
        },
        {
          field: "Remark",
          title: "備註",
          span: 24,
          itemRender: {
            name: "$textarea",
            props: {
              autosize: { minRows: 2, maxRows: 4 },
              placeholder: "內容",
            },
          },
        },
        {
          align: "right",
          span: 24,
          titleAlign: "left",
          itemRender: {
            name: "$buttons",
            children: [
              { props: { type: "submit", content: "確認", status: "primary" } },
              { props: { type: "reset", content: "重置" } },
            ],
          },
        }, //, events: { click: save }
      ] as VxeFormPropTypes.Items,
    });

    onMounted(() => {});

    return {
      form,
      grid,
      gridOptions,
      loading,
      isEditing,
      editingRow,
      isRowSelected,
      keyword,
      customFilters,
      submitSave,
    };
  },
  methods: {
    reset() {
      this.form.formData.Id = 0;
      this.form.formData.CreatedTimeString = "";
      this.form.formData.CreatedTime = "";
      this.form.formData.Name = "";
      this.form.formData.Ordinal = 0;
      this.form.formData.Remark = "";
      this.form.formData.Published = false;
    },
    edit(row: any) {
      this.$model.dispatch("linkCategory/find", row.Id).then(
        (paylod) => {
          Object.assign(this.form.formData, paylod);
          this.form.model = "edit";
          this.form.showEdit = true;
        },
        (failure) =>
          this.$send("error", {
            subject: "操作失敗！",
            content: failure.message,
          })
      );
    },
    addNewRow() {
      this.reset();
      this.form.model = "insert";
      this.form.showEdit = true;
    },
    save(row?: any) {
      const {
        insertRecords,
        updateRecords,
        removeRecords,
      } = this.grid.getRecordset();
      let needSave =
        insertRecords.length || updateRecords.length || removeRecords.length;
      needSave &&=
        row &&
        (insertRecords.some((e) => e._XID === row._XID) ||
          updateRecords.some((e) => e._XID === row._XID) ||
          removeRecords.some((e) => e._XID === row._XID));
      const isRowEditing = row && this.editingRow === row;
      if (needSave) {
        this.grid.commitProxy("save").then(() => {
          if (isRowEditing && this.editingRow === row) this.edit(row);
          else this.editingRow = null;
        });
      } else if (!row || row === this.editingRow) this.editingRow = null;
    },
    remove(row: any) {
      if (row && confirm("確定要刪除此筆資料嗎?")) {
        this.gridOptions.loading = true;
        this.$model.dispatch("linkCategory/delete", row.Id).then(
          () => {
            this.grid.commitProxy("query").finally(() => {
              this.gridOptions.loading = false;
            });
          },
          (failure) =>
            this.$send("error", {
              subject: "相關連結分類刪除失敗",
              content: failure.message,
            })
        );
      }
    },
    removeSelectedRows() {
      cash("#batch-dropdown").dropdown("hide");
      const rows = this.grid.getCheckboxRecords();
      if (rows && rows.length && confirm("確定要刪除已被選擇的資料嗎?")) {
        this.grid.commitProxy("delete");
      }
    },
    changeFilterEvent(
      event: Event,
      option: VxeColumnPropTypes.Filter,
      panel: any,
      toogleChecked?: boolean
    ) {
      if (toogleChecked) option.checked = !option.checked;
      if (option.checked && option.data) {
        if (!option.value)
          option.value = new Condition("Name", Operator.Contains);
        option.value.value = option.data;
      } else option.value = option.data = undefined;
      if (toogleChecked || option.checked)
        panel.changeOption(event, !!option.data, option);
    },
    async onExport(
      type: "csv" | "html",
      mode: "current" | "selected" | "all" = "all"
    ) {
      const config: any = { ...this.gridOptions.exportConfig, type, mode };
      if (mode === "all")
        config.data = await this.$model.dispatch("linkCategory/query");
      this.grid.exportData({ ...config, type, mode });
    },
    async onPrint(mode: "current" | "selected" | "all" = "all") {
      const config: any = { ...this.gridOptions.printConfig, mode };
      if (mode === "all")
        config.data = await this.$model.dispatch("linkCategory/query");
      this.grid.print(config);
    },
  },
});
