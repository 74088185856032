<template>
  <vxe-pulldown
    class="w-full" 
    :ref="(el) => (instance = el)"
    :transfer="transfer"
    @hide-panel="onHidePanel"
  >
    <template #default>
      <div class="relative">
        <div v-if="multiselect" class="mr-6">
          <vue-tags-input
            :ref="(el) => (tagsInput = el)"
            :placeholder="placeholder"
            :disabled="readonly"
            :readonly="!addIfNotExists"
            :add-on-blur="false"
            :delete-on-backspace="false"
            :tags="selectedRows"
            :is-duplicate="(tags, tag) => tags.some(e => e.value === tag.value)"
            v-model="tag"
            @focus="onFocus"
            @before-deleting-tag="onBeforeDeletingTag"
            @before-adding-tag="onBeforeAddingTag"
          />
        </div>
        <div v-else class="mr-6">
          <vxe-input
            v-if="showSearch"
            class="w-full"
            :clearable="!readonly"
            :placeholder="placeholder"
            :readonly="true"
            v-model="text"
            @focus="onFocus"
            @clear="onClear"
          />
          <vxe-input
            v-else
            class="w-full"
            :clearable="!readonly"
            :placeholder="placeholder"
            :readonly="disabled"
            v-model.trim="keyword"
            @change="onKeywordChange"
            @focus="onFocus"
            @clear="onClear"
          />
        </div>
        <div 
          class="absolute top-0 right-0 rounded-r w-6 h-full flex items-center justify-center bg-white border"
          @click.stop="instance.togglePanel()"
        >
          <i :class="instance.isPanelVisible && instance.isPanelVisible() ? 'vxe-icon--caret-top' : 'vxe-icon--caret-bottom'" />
        </div>
      </div>
    </template>
    <template #dropdown>
      <div 
        class="border rounded bg-gray-300"
        :style="{ 
          width: typeof dropdownWidth !== 'string' ? `${dropdownWidth}px` : dropdownWidth, 
          height: typeof dropdownHeight !== 'string' ? `${dropdownHeight}px` : dropdownHeight,
        }"
      >
        <grid
          :ref="(el) => (grid = el)"
          class="border rounded"
          v-bind="gridOptions"
          :rowClassNmae="({ row }) => { return disallowSelectedRowIds.some(id => id === row[rowId]) ? 'bg-opacity-30 opacity-30' : undefined }"
          @currentRowChanged="onSelectedRowChanged"
          @checkboxChange="onCheckboxChange"
          @checkboxAll="onCheckboxAll"
        >
          <template #form>
            <vxe-input
              v-if="multiselect || showSearch"
              class="w-full"
              type="search"
              :clearable="true"
              placeholder="請輸入關鍵字"
              v-model.trim="grid.keyword"
              @change="onKeywordChange"
            />
          </template>
        </grid>
      </div>
    </template>
  </vxe-pulldown>
</template>

<script lang="ts">
/* eslint-disable */
import { defineComponent, ref, Sorting, Condition, PropType, onMounted } from "@cloudfun/core";
import { VxeGridPropTypes, VxePulldownInstance } from "vxe-table";
import Grid, { GridOptions, TreeConfig } from "@/cloudfun/components/Grid.vue";
import VueTagsInput from "@sipec/vue3-tags-input";

export type SelectBoxOptions = {
  /** 資料主鍵 */
  rowId: string;
  /** 文字欄位 */
  textField: string;
  /** 值欄位 */
  valueField: string;
  /** 格式化文字 */
  formatText?: (row: any) => Promise<string>;
  /** 所有欄位 */
  columns: VxeGridPropTypes.Columns;
  /** 提示文字 */
  placeholder?: string;
  /** 展開方向 */
  placement?: "top" | "bottom";
  /** 是否複選 */
  multiselect?: boolean;
  /** 是否唯讀 */
  readonly?: boolean;
  /** 顯示搜尋框 */
  showSearch?: boolean;
  /** 顯示表頭 */
  showHeader?: boolean;
  /** 下拉寬度 */
  dropdownWidth?: number | string;
  /** 下拉高度 */
  dropdownHeight?: number | string;
  /** 當資料不存在時新增資料 */
  addIfNotExists?: boolean, 
  /** 不能選擇的資料行 */
  disallowSelectedRowIds?: any[];
  /** 分頁設定 */
  pagerConfig?: VxeGridPropTypes.PagerConfig;
  /** 樹狀結構設定 */
  treeConfig?: TreeConfig;
  /** 可提供的承諾 */
  promises: {
    /** 以傳入值尋找資料 */
    find: (value: any) => Promise<any>;
    /** 查詢資料 */
    query: (params: {
      page: number;
      pageSize: number;
      keyword?: string;
      sortings?: Sorting[];
      condition?: Condition;
    }) => Promise<{ data: any[]; totalCount: number }>;
    /** 新增資料 */
    insert?: (row: any) => Promise<any> 
  };
  /** 是否轉移至最上層 */
  transfer?: boolean;
};

export default defineComponent({
  components: {
    Grid,
    VueTagsInput,
  },
  props: {
    /** 綁定值 */
    modelValue: [Object, Array],
    /** 資料主鍵 */
    rowId: { type: String, required: true },
    /** 文字欄位 */
    textField: { type: String, required: true },
    /** 值欄位 */
    valueField: { type: String, required: true },
    /** 格式化文字 */
    formatText: { type: Object as PropType<(rows: any) => Promise<string>> },
    /** 所有欄位 */
    columns: { type: Object as PropType<VxeGridPropTypes.Columns>, required: true },
    /** 提示文字 */
    placeholder: String,
    /** 展開方向 */
    placement: { type: Object as PropType<"top" | "bottom">, default: "bottom" },
    /** 是否複選 */
    multiselect: { type: Boolean, default: false },
    /** 是否唯讀 */
    readonly: { type: Boolean, default: false },
    /** 顯示搜尋框 */
    showSearch: Boolean,
    /** 顯示表頭 */
    showHeader: { type: Boolean, default: undefined },
    /** 下拉寬度 */
    dropdownWidth: { type: Object as PropType<number | string> },
    /** 下拉高度 */
    dropdownHeight: { type: Object as PropType<number | string>, default: 282 },
    /** 當資料不存在時新增資料 */
    addIfNotExists: Boolean, 
    /** 不能選擇的資料行 */
    disallowSelectedRowIds: { type: Array as PropType<any[]>,  default: [] },
    /** 分頁設定 */
    pagerConfig: { type: Object as PropType<VxeGridPropTypes.PagerConfig>, default: { size: "mini", currentPage: 1, pageSize: 5, layouts: ["PrevPage", "Jump", "PageCount", "NextPage"], autoHidden: true, className: 'border-b-2 rounded-b' } },
    /** 樹狀結構設定 */
    treeConfig: { type: Object as PropType<TreeConfig> },
    /** 可提供的承諾 */
    promises: {
      type: Object as PropType<{
        /** 以傳入值尋找資料 */
        find: (value: any) => Promise<any>;
        /** 查詢資料 */
        query: (params: { page: number, pageSize: number, keyword?: string, sortings?: Sorting[], condition?: Condition }) => Promise<{ data: any[], totalCount: number }>;
        /** 新增資料 */
        insert?: (row: any) => Promise<any>
      }>,
      required: true,
    },
    /** 是否轉移至最上層 */
    transfer: Boolean,
  },
  setup(props) {
    const instance = ref({} as VxePulldownInstance);
    const selectedRows = ref<any[]>([]);
    const tagsInput = ref<any>({});
    const tag = ref('');
    const grid = ref<any>({});
    const value = ref<any | any[]>(props.modelValue);

    onMounted(async () => {
      if (props.modelValue) {
        const values = Array.isArray(props.modelValue) ? props.modelValue : [props.modelValue];
        for (const value of values) {
          await props.promises.find(value).then(async (row) => {
            selectedRows.value.push({ 
              id: row[props.rowId], 
              text: props.formatText ? await props.formatText(row) : row[props.textField],
              value: row[props.valueField],
            });
          }).catch((error) => console.log("SelectBox: Failure to find model value: ", error));
        }
      }
    });

    const findChildRow = (rowId: any, children: any[]) => {
      for (const row of children) {
        if (row[props.rowId] === rowId) return row;
        if (props.treeConfig?.children && row[props.treeConfig.children]) {
          const child: any = findChildRow(rowId, row[props.treeConfig.children]);
          if (child) return child;
        }
      }
    }

    const findGridRow = (rowId: any) => {
      if (!grid.value.getData) return undefined;
      const data = grid.value.getData();
      return findChildRow(rowId, data);
    }

    const gridOptions: GridOptions = {
      rowId: props.rowId,
      size: "mini",
      height: "auto",
      autoResize: true,
      multiselect: props.multiselect,
      columns: props.columns,
      showHeader: props.showHeader !== undefined ? props.showHeader : props.columns.length > 1,
      pagerConfig: props.pagerConfig,
      treeConfig: props.treeConfig,
      promises: { query: props.promises.query },
      canCreateRow: false,
      canUpdateRow: false,
      canDeleteRow: false,
      canReadRow: false,
      checkboxConfig: {
        reserve: true,
        checkRowKeys: Array.isArray(props.modelValue) ? props.modelValue : [props.modelValue],
        checkMethod: ({ row }) => !props.readonly && !props.disallowSelectedRowIds.some(id => id === row[props.rowId]),
        strict: props.readonly,
      },
    };

    return {
      instance,
      selectedRows,
      tagsInput,
      tag,
      grid,
      gridOptions,
      findGridRow,
      value,
    };
  },
  watch: {
    async modelValue(current) {
      if (current != this.value && this.grid.clearCheckboxRow) {
        this.selectedRows = [];
        this.grid.clearCheckboxRow();
        this.grid.clearCheckboxReserve();
        if (current) {
          const values = Array.isArray(current) ? current : [current];
          for (const value of values) {
            await this.promises.find(value).then(async (row) => {
              this.grid.setCheckboxRow(row, true);
              this.selectedRows.push({ 
                id: row[this.rowId], 
                text: this.formatText ? await this.formatText(row) : row[this.textField],
                value: row[this.valueField],
              });
            }).catch((error) => console.log("SelectBox: Failure to find model value: ", error));
          }
        }
      }
    }
  },
  computed: {
    text(): string | undefined {
      return this.selectedRows.map((e) => e.text).join();
    },
    keyword: {
      get: function (): string { return this.grid.refresh ? this.grid.keyword : this.text; },
      set: function (value: string) { this.grid.keyword = value; },
    },
  },
  methods: {
    computeValue() {
      if (this.multiselect) return this.selectedRows.map((e) => e.row ? e.row[this.valueField] : e.value); // multiselect
      return this.selectedRows[0]?.row[this.valueField];
    },
    async onFocus() {
      await this.instance.showPanel();
      if (!this.multiselect && !this.showSearch) {
        this.grid.keyword = this.text;
        this.grid.refresh();
      }
    },
    async onHidePanel() {
      if (!this.showSearch && this.grid.keyword) {
        const data = this.grid.getData();
        if (data.length === 1) {
          const row = data[0];
          this.selectedRows = [{ 
            id: row[this.rowId],
            text: this.formatText ? await this.formatText(row) : row[this.textField],
            value: row[this.valueField],
            row: row,
          }]
          this.grid.keyword = row[this.textField];
          this.value = this.computeValue();
          this.$emit("update:modelValue", this.value);
          this.$emit("change", this.value);
        }
        if (!this.selectedRows.length) this.grid.keyword = null;
      }
    },
    onKeywordChange() {
      this.grid.refresh();
      if (!this.multiselect && !this.showSearch) {
        this.selectedRows = [];
        this.value = this.computeValue();
        this.$emit("update:modelValue", this.value);
        this.$emit("change", this.value);
      }
    },
    async onSelectedRowChanged(gridRow: any) {
      if (this.readonly || this.multiselect) return;
      if (this.selectedRows.some(e => e.id === gridRow[this.rowId])) return;
      if (this.disallowSelectedRowIds?.includes(gridRow[this.rowId])) this.$emit("disallowSelect", gridRow);
      const row = {
        id: gridRow[this.rowId],
        text: this.formatText ? await this.formatText(gridRow) : gridRow[this.textField],
        value: gridRow[this.valueField],
        row: gridRow,
      };
      this.selectedRows = [row];
      if (!this.showSearch) this.grid.keyword = this.text;
      this.value = this.computeValue();
      this.$emit("update:modelValue", this.value);
      this.$emit("change", this.value);
    },
    onClear() {
      this.selectedRows = [];
      this.value = this.computeValue();
      this.$emit("update:modelValue", this.value);
      this.$emit("change", this.value);
    },
    async onCheckboxChange({ checked, records, reserves, row }: any) {
      if (this.readonly) return;
      if (checked) {
        if (this.disallowSelectedRowIds?.includes(row[this.rowId])) this.$emit("disallowSelect", row);
        let checkingRows = [...records]; 
        if (this.treeConfig?.children) checkingRows = checkingRows.filter((e: any) => !e[this.treeConfig!.children!]?.length); // eslint-disable-line 
        for (const record of checkingRows) {
          const selectedRow = this.selectedRows.find(e => e.id === record[this.rowId]);
          if (selectedRow) { selectedRow.row = record; continue; }
          this.selectedRows.push({ 
            id: record[this.rowId], 
            text: this.formatText ? await this.formatText(record) : record[this.textField],
            value: record[this.valueField],
            row: record 
          });
        }
      } else {
        const selectedRows: any[] = [];
        for (const record of [...records, ...reserves]) {
          const selectedRow = this.selectedRows.find(e => e.id === record[this.rowId]);
          if (selectedRow) { selectedRow.row = record; selectedRows.push(selectedRow); }
        }
        this.selectedRows = selectedRows;
      }
      this.value = this.computeValue();
      this.$emit("update:modelValue", this.value);
      this.$emit("change", this.value);
    },
    async onCheckboxAll({ checked, records, reserves }: any) {
      if (this.readonly) return;
      if (checked) {
        for (const record of records) {
          const selectedRow = this.selectedRows.find(e => e.id === record[this.rowId])
          if (selectedRow) { selectedRow.row = record; continue; }
          this.selectedRows.push({ 
            id: record[this.rowId], 
            text: this.formatText ? await this.formatText(record) : record[this.textField], 
            value: record[this.valueField],
            row: record 
          });
        }
      } else {
        const selectedRows: any[] = [];
        for (const record of reserves) {
          const selectedRow = this.selectedRows.find(e => e.id === record[this.rowId]);
          if (selectedRow) { selectedRow.row = record; selectedRows.push(selectedRow); }
        }
        this.selectedRows = selectedRows;
      }
      this.value = this.computeValue();
      this.$emit("update:modelValue", this.value);
      this.$emit("change", this.value);
    },
    async onBeforeDeletingTag({ tag }: any) {
      if (this.readonly) return;
      if (!this.grid.getData) { this.instance.showPanel(); return; }
      const unselectedRow = this.selectedRows.find((e: any) => e.id === tag.id);
      if (!unselectedRow.row) unselectedRow.row = this.findGridRow(unselectedRow.id);
      await this.grid.setCheckboxRow(unselectedRow.row || { [this.rowId]: tag.id }, false);
      this.selectedRows = this.selectedRows.filter(e => e.id !== tag.id);
      this.value = this.computeValue();
      this.$emit("update:modelValue", this.value);
      this.$emit("change", this.value);
    },
    async onBeforeAddingTag() {
      if (this.selectedRows.some(e => e.row && e.row[this.textField] === this.tag)) return
      if (this.addIfNotExists && this.promises.insert) {
        let row = await this.promises.insert({ [this.textField]: this.tag });
        await this.grid.refresh();
        const gridRow = this.findGridRow(row[this.rowId]);
        if (gridRow) row = gridRow;
        await this.grid.setCheckboxRow(row, true);
        this.selectedRows.push({ 
          id: row[this.rowId], 
          text: this.formatText ? await this.formatText(row) : row[this.textField], 
          value: row[this.valueField],
          row: gridRow || undefined,
        });
      }
      this.tag = '';
      this.value = this.computeValue();
      this.$emit("update:modelValue", this.value);
      this.$emit("change", this.value);
    },
  },
});
</script>

<style scoped>
.vue-tags-input {
  max-width: none;
  width: 100%;
  min-height: 32px;
}
</style>
