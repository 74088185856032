import { ISitemapNode } from "@cloudfun/core";

const sitemap: ISitemapNode = {
  icon: "HomeIcon",
  to: "",
  title: "Home",
  subNodes: [
    {
      icon: "HomeIcon",
      to: "dashboard",
      title: "Dashboard",
    },
    {
      icon: "SettingsIcon",
      to: "configuration",
      title: "系統配置",
    },
    {
      icon: "DatabaseIcon",
      to: "system/store-grade",
      title: "基礎資料管理",
      subNodes: [
        // {
        //   icon: "UsersIcon",
        //   to: "system/store-grade",
        //   title: "經銷商級別",
        // },
        {
          icon: "PenToolIcon",
          to: "system/color",
          title: "顏色",
        },
        // {
        //   icon: "CrosshairIcon",
        //   to: "system/size",
        //   title: "尺寸",
        // },
        {
          icon: "GlobeIcon",
          to: "system/country",
          title: "產地",
        },
        {
          icon: "PackageIcon",
          to: "system/material",
          title: "材質",
        },
        // {
        //   icon: "ChevronRightIcon",
        //   to: "system/category/main",
        //   title: "ERP主類別",
        // },
        // {
        //   icon: "ChevronsRightIcon",
        //   to: "system/category/sub",
        //   title: "ERP中類別",
        // },
        {
          icon: "TagIcon",
          to: "system/tag",
          title: "產品標籤",
        },
        // {
        //   icon: "PackageIcon",
        //   to: "system/warehouse",
        //   title: "倉庫",
        // },
        {
          icon: "MenuIcon",
          title: "前台產品選單",
          to: "category-main",
        },
      ],
    },
    // {
    //   icon: "FileTextIcon",
    //   to: 'organization',
    //   title: "關於我們",
    //   subNodes: [
    //     {
    //       icon: "FileTextIcon",
    //       to: 'organization',
    //       title: "組織架構",
    //     },
    //     {
    //       icon: "FileTextIcon",
    //       to: 'task',
    //       title: "成立宗旨及任務",
    //     },
    //     {
    //       icon: "FileTextIcon",
    //       to: 'privacy',
    //       title: "隱私權政策",
    //     },
    //   ]
    // },
    {
      icon: "UserIcon",
      to: "user",
      title: "用戶管理",
      subNodes: [
        {
          icon: "UsersIcon",
          to: "role",
          title: "角色管理",
        },
        {
          icon: "UserIcon",
          to: "user",
          title: "用戶資訊",
        },
      ],
    },
    {
      icon: "ActivityIcon",
      to: "action-log",
      title: "操作紀錄",
    },
    {
      icon: "FlagIcon",
      to: "banner",
      title: "首頁圖片管理",
    },
    {
      icon: "AlignLeftIcon",
      to: "order",
      title: "訂單管理",
    },
    {
      icon: "AlignLeftIcon",
      to: "shop-dev",
      title: "購物車管理",
    },

    {
      icon: "ListIcon",
      to: "industryCategory",
      title: "產業資訊",
      subNodes: [
        {
          icon: "AlignLeftIcon",
          to: "industryCategory",
          title: "分類管理",
        },
        {
          icon: "AlignLeftIcon",
          to: "industryInfo",
          title: "資訊管理",
        },
        {
          icon: "AlignLeftIcon",
          to: "book",
          title: "出版刊物",
        },
        {
          icon: "AlignLeftIcon",
          to: "order",
          title: "訂單管理",
        },
      ],
    },
    {
      icon: "ListIcon",
      to: "businessTopic",
      title: "討論區管理",
      subNodes: [
        {
          icon: "ListIcon",
          to: "businessTopic",
          title: "商情諮詢",
          subNodes: [
            {
              icon: "AlignLeftIcon",
              to: "businessCategory",
              title: "分類管理",
            },
            {
              icon: "AlignLeftIcon",
              to: "businessTopic",
              title: "主題管理",
            },
            {
              icon: "AlignLeftIcon",
              to: "businessReply",
              title: "回覆管理",
            },
          ],
        },
        {
          icon: "ListIcon",
          to: "questionTopic",
          title: "常見問題",
          subNodes: [
            {
              icon: "AlignLeftIcon",
              to: "questionCategory",
              title: "分類管理",
            },
            {
              icon: "AlignLeftIcon",
              to: "questionTopic",
              title: "主題管理",
            },
            {
              icon: "AlignLeftIcon",
              to: "questionReply",
              title: "回覆管理",
            },
          ],
        },
        {
          icon: "ListIcon",
          to: "exchangeTopic",
          title: "交流園地",
          subNodes: [
            {
              icon: "AlignLeftIcon",
              to: "exchangeCategory",
              title: "分類管理",
            },
            {
              icon: "AlignLeftIcon",
              to: "exchangeTopic",
              title: "主題管理",
            },
            {
              icon: "AlignLeftIcon",
              to: "exchangeReply",
              title: "回覆管理",
            },
          ],
        },
      ],
    },
    {
      icon: "FileTextIcon",
      to: "about",
      title: "簡介管理",
      subNodes: [
        {
          icon: "FileTextIcon",
          to: "industry",
          title: "認識輕金屬",
        },
        {
          icon: "FileTextIcon",
          to: "about",
          title: "關於TWLMA",
        },
      ],
    },
    {
      icon: "ListIcon",
      to: "fileCategory",
      title: "下載專區",
      subNodes: [
        {
          icon: "AlignLeftIcon",
          to: "fileCategory",
          title: "分類管理",
        },
        {
          icon: "AlignLeftIcon",
          to: "downloadManagement",
          title: "檔案管理",
        },
      ],
    },
    {
      icon: "MailIcon",
      to: "news",
      title: "最新消息",
    },
    {
      icon: "GridIcon",
      to: "topic",
      title: "主題管理",
    },
    {
      icon: "AnchorIcon",
      to: "link",
      title: "相關連結",
      subNodes: [
        {
          icon: "GitMergeIcon",
          to: "link-category",
          title: "分類管理",
        },
        {
          icon: "FileTextIcon",
          to: "link",
          title: "內容資訊",
        },
      ],
    },
    // {
    //   icon: "MailIcon",
    //   to: "test",
    //   title: "測試",
    //   // subNodes: [
    //   //   {
    //   //     icon: "GitMergeIcon",
    //   //     to: 'news-category',
    //   //     title: "分類管理",
    //   //   },
    //   //   {
    //   //     icon: "FileTextIcon",
    //   //     to: 'news',
    //   //     title: "內容資訊",
    //   //   }
    //   // ]
    // },
    // {
    //   icon: "MailIcon",
    //   to: "test2",
    //   title: "測試2",
    //   // subNodes: [
    //   //   {
    //   //     icon: "GitMergeIcon",
    //   //     to: 'news-category',
    //   //     title: "分類管理",
    //   //   },
    //   //   {
    //   //     icon: "FileTextIcon",
    //   //     to: 'news',
    //   //     title: "內容資訊",
    //   //   }
    //   // ]
    // },
    // {
    //   icon: "MailIcon",
    //   to: "form-test",
    //   title: "測試 Form",
    //   // subNodes: [
    //   //   {
    //   //     icon: "GitMergeIcon",
    //   //     to: 'news-category',
    //   //     title: "分類管理",
    //   //   },
    //   //   {
    //   //     icon: "FileTextIcon",
    //   //     to: 'news',
    //   //     title: "內容資訊",
    //   //   }
    //   // ]
    // },
    // {
    //   icon: "MailIcon",
    //   to: "dropdown",
    //   title: "測試 DropDown",
    // },

    "devider",
    // {
    //   icon: "MailIcon",
    //   to: "dealer",
    //   title: "會員管理",
    // },
    {
      icon: "PackageIcon",
      to: "product",
      title: "產品管理",
    },
    // {
    //   icon: "PackageIcon",
    //   title: "Dev購物車",
    //   to: "shop-dev",
    // },
    // "devider",
    // {
    //   to: "select-box",
    //   title: "SelectBox",
    // },
    // {
    //   to: "file-uploader",
    //   title: "FileUploader",
    // },
    // {
    //   to: "dropdown-box",
    //   title: "DropdownBox",
    // },
    // {
    //   icon: "BoxIcon",
    //   title: "Menu Layout",
    //   subNodes: [
    //     {
    //       icon: "",
    //       to: '/dashboard',
    //       title: "Side Menu",
    //       ignore: true,
    //     },
    //     {
    //       icon: "",
    //       to: "/simple-menu/dashboard",
    //       title: "Simple Menu",
    //       ignore: true,
    //     },
    //     {
    //       icon: "",
    //       to: "/top-menu/dashboard",
    //       title: "Top Menu",
    //       ignore: true,
    //     },
    //   ],
    // },
    // {
    //   icon: "InboxIcon",
    //   to: "inbox",
    //   title: "Inbox",
    // },
    // {
    //   icon: "HardDriveIcon",
    //   to: "file-manager",
    //   title: "File Manager",
    // },
    // {
    //   icon: "CreditCardIcon",
    //   to: "point-of-sale",
    //   title: "Point of Sale",
    // },
    // {
    //   icon: "MessageSquareIcon",
    //   to: "chat",
    //   title: "Chat",
    // },
    // {
    //   icon: "FileTextIcon",
    //   to: "post",
    //   title: "Post",
    // },
    // "devider",
    // {
    //   icon: "EditIcon",
    //   to: "crud-data-list",
    //   title: "Crud",
    //   subNodes: [
    //     {
    //       icon: "",
    //       to: "crud-data-list",
    //       title: "Data List",
    //     },
    //     {
    //       icon: "",
    //       to: "crud-form",
    //       title: "Form",
    //     },
    //   ],
    // },
    // {
    //   icon: "UsersIcon",
    //   to: "users-layout-1",
    //   title: "Users",
    //   subNodes: [
    //     {
    //       icon: "",
    //       to: "users-layout-1",
    //       title: "Layout 1",
    //     },
    //     {
    //       icon: "",
    //       to: "users-layout-2",
    //       title: "Layout 2",
    //     },
    //     {
    //       icon: "",
    //       to: "users-layout-3",
    //       title: "Layout 3",
    //     },
    //   ],
    // },
    // {
    //   icon: "TrelloIcon",
    //   to: "profile-overview-1",
    //   title: "Profile",
    //   subNodes: [
    //     {
    //       icon: "",
    //       to: "profile-overview-1",
    //       title: "Overview 1",
    //     },
    //     {
    //       icon: "",
    //       to: "profile-overview-2",
    //       title: "Overview 2",
    //     },
    //     {
    //       icon: "",
    //       to: "profile-overview-3",
    //       title: "Overview 3",
    //     },
    //   ],
    // },
    // {
    //   icon: "LayoutIcon",
    //   to: "wizard-layout-1",
    //   title: "Pages",
    //   subNodes: [
    //     {
    //       icon: "",
    //       to: "wizard-layout-1",
    //       title: "Wizards",
    //       subNodes: [
    //         {
    //           icon: "",
    //           to: "wizard-layout-1",
    //           title: "Layout 1",
    //         },
    //         {
    //           icon: "",
    //           to: "wizard-layout-2",
    //           title: "Layout 2",
    //         },
    //         {
    //           icon: "",
    //           to: "wizard-layout-3",
    //           title: "Layout 3",
    //         },
    //       ],
    //     },
    //     {
    //       icon: "",
    //       to: "blog-layout-1",
    //       title: "Blog",
    //       subNodes: [
    //         {
    //           icon: "",
    //           to: "blog-layout-1",
    //           title: "Layout 1",
    //         },
    //         {
    //           icon: "",
    //           to: "blog-layout-2",
    //           title: "Layout 2",
    //         },
    //         {
    //           icon: "",
    //           to: "blog-layout-3",
    //           title: "Layout 3",
    //         },
    //       ],
    //     },
    //     {
    //       icon: "",
    //       to: "pricing-layout-1",
    //       title: "Pricing",
    //       subNodes: [
    //         {
    //           icon: "",
    //           to: "pricing-layout-1",
    //           title: "Layout 1",
    //         },
    //         {
    //           icon: "",
    //           to: "pricing-layout-2",
    //           title: "Layout 2",
    //         },
    //       ],
    //     },
    //     {
    //       icon: "",
    //       to: "invoice-layout-1",
    //       title: "Invoice",
    //       subNodes: [
    //         {
    //           icon: "",
    //           to: "invoice-layout-1",
    //           title: "Layout 1",
    //         },
    //         {
    //           icon: "",
    //           to: "invoice-layout-2",
    //           title: "Layout 2",
    //         },
    //       ],
    //     },
    //     {
    //       icon: "",
    //       to: "faq-layout-1",
    //       title: "FAQ",
    //       subNodes: [
    //         {
    //           icon: "",
    //           to: "faq-layout-1",
    //           title: "Layout 1",
    //         },
    //         {
    //           icon: "",
    //           to: "faq-layout-2",
    //           title: "Layout 2",
    //         },
    //         {
    //           icon: "",
    //           to: "faq-layout-3",
    //           title: "Layout 3",
    //         },
    //       ],
    //     },
    //     {
    //       icon: "",
    //       to: "login",
    //       title: "Login",
    //     },
    //     {
    //       icon: "",
    //       to: "register",
    //       title: "Register",
    //     },
    //     {
    //       icon: "",
    //       to: "error-page",
    //       title: "Error Page",
    //     },
    //     {
    //       icon: "",
    //       to: "update-profile",
    //       title: "Update profile",
    //     },
    //     {
    //       icon: "",
    //       to: "change-password",
    //       title: "Change Password",
    //     },
    //   ],
    // },
    // "devider",
    // {
    //   icon: "InboxIcon",
    //   to: "regular-table",
    //   title: "Components",
    //   subNodes: [
    //     {
    //       icon: "",
    //       to: "regular-table",
    //       title: "Table",
    //       subNodes: [
    //         {
    //           icon: "",
    //           to: "regular-table",
    //           title: "Regular Table",
    //         },
    //         {
    //           icon: "",
    //           to: "tabulator",
    //           title: "Tabulator",
    //         },
    //       ],
    //     },
    //     {
    //       icon: "",
    //       to: "accordion",
    //       title: "Accordion",
    //     },
    //     {
    //       icon: "",
    //       to: "button",
    //       title: "Button",
    //     },
    //     {
    //       icon: "",
    //       to: "modal",
    //       title: "Modal",
    //     },
    //     {
    //       icon: "",
    //       to: "alert",
    //       title: "Alert",
    //     },
    //     {
    //       icon: "",
    //       to: "progress-bar",
    //       title: "Progress Bar",
    //     },
    //     {
    //       icon: "",
    //       to: "tooltip",
    //       title: "Tooltip",
    //     },
    //     {
    //       icon: "",
    //       to: "dropdown",
    //       title: "Dropdown",
    //     },
    //     {
    //       icon: "",
    //       to: "toast",
    //       title: "Toast",
    //     },
    //     {
    //       icon: "",
    //       to: "typography",
    //       title: "Typography",
    //     },
    //     {
    //       icon: "",
    //       to: "icon",
    //       title: "Icon",
    //     },
    //     {
    //       icon: "",
    //       to: "loading-icon",
    //       title: "Loading Icon",
    //     },
    //   ],
    // },
    // {
    //   icon: "SidebarIcon",
    //   to: "regular-form",
    //   title: "Forms",
    //   subNodes: [
    //     {
    //       icon: "",
    //       to: "regular-form",
    //       title: "Regular Form",
    //     },
    //     {
    //       icon: "",
    //       to: "datepicker",
    //       title: "Datepicker",
    //     },
    //     {
    //       icon: "",
    //       to: "tail-select",
    //       title: "Tail Select",
    //     },
    //     {
    //       icon: "",
    //       to: "file-upload",
    //       title: "File Upload",
    //     },
    // {
    //   icon: "",
    //   to: "wysiwyg-editor",
    //   title: "Wysiwyg Editor",
    // },
    //     {
    //       icon: "",
    //       to: "validation",
    //       title: "Validation",
    //     },
    //   ],
    // },
    // {
    //   icon: "HardDriveIcon",
    //   to: "chart",
    //   title: "Widgets",
    //   subNodes: [
    //     {
    //       icon: "",
    //       to: "chart",
    //       title: "Chart",
    //     },
    //     {
    //       icon: "",
    //       to: "slider",
    //       title: "Slider",
    //     },
    //     {
    //       icon: "",
    //       to: "image-zoom",
    //       title: "Image Zoom",
    //     },
    //   ],
    // },
  ],
};

export default sitemap;
