<template>
  <form @submit.prevent="config.onSubmit" :enctype="config.encryptType">
    <!--form-data.sync 會嚴重影像效能-->
    <dx-form
      :form-data="formData"
      :items="config.items"
      :on-initialized="config.onInitialized"
      :on-content-ready="config.onContentReady"
      :on-field-data-changed="config.onFieldDataChanged"
      :label-location="config.labelLocation"
    >
      <template v-for="item in itemTemplates" v-slot:[item.template]="{ data }">
        <slot :name="item.template" :data="data" />
      </template>
    </dx-form>
  </form>
</template>
<script>
import { cloneDeep } from "../../assets/scripts/cloudfun/clone";
// Due to our UI design, we share the same form all over the time.
// Some option such as itemOption ...etc,
// will cause the entire form to refresh(not partial).
import { DxForm } from "devextreme-vue/form";
import { defineComponent } from "vue";
export default defineComponent({
  components: {
    DxForm,
    // DxItem,
  },
  props: {
    formData: {
      type: [Object, Array],
    },
    options: {
      type: Object,
    },
  },
  setup(props) {
    const config = cloneDeep(
      {
        encryptType: "application/x-www-form-urlencoded",
        submitButtonSettings: {
          visible: true,
          text: "確定",
        },
        labelLocation: "left",
        onSubmit: () => {
          console.log("submit");
        },
      },
      props.options
    );

    const flatizeItems = config.items
      ? config.items.flatMap((e) => (e.items ? e.items : e))
      : [];
    const itemTemplates = flatizeItems.filter((e) => e && e.template);
    if (config.submitButtonSettings.visible) {
      config.items.push({
        itemType: "group",
        items: [
          {
            itemType: "button",
            name: "defaultSubmitButton",
            buttonOptions: {
              text: config.submitButtonSettings.text,
              type: "default",
              useSubmitBehavior: true,
              onClick: config.submitButtonSettings.onClick,
            },
          },
        ],
      });
    }

    function overrideOnContentReady(config, flatizeItems) {
      const enableItemNames = flatizeItems
        .filter(
          (e) =>
            e &&
            e.dataField &&
            (!e.editorOptions ||
              (!e.editorOptions.disabled && !e.editorOptions.readOnly))
        )
        .map((e) => e.dataField);

      config.onContentReady = (() => {
        const func = config.onContentReady;
        return function (e) {
          if (func) func.call(this, e);
          // 使用 enter 取代 tab
          // enableItemNames.forEach((field, idx) => {
          //   const editor = e.component.getEditor(field);
          //   if (editor) {
          //     editor.registerKeyHandler("enter", function () {
          //       if (~["dxSelectBox", "dxLookUp"].indexOf(editor.NAME))
          //         editor.close();
          //       const nextName = enableItemNames[idx + 1];
          //       const nextEditor = e.component.getEditor(nextName);
          //       if (nextEditor) nextEditor.focus();
          //     });
          //   }
          // });
        };
      })();
    }

    overrideOnContentReady(config, flatizeItems);
    return {
      config,
      itemTemplates,
      overrideOnContentReady,
    };
  },
  methods: {},
});
</script>
<style></style>
