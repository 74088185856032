
import { defineComponent, computed, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { helper as $h } from "@/utils/helper";
import TopBar from "@/components/top-bar/Main.vue";
import MobileMenu from "@/components/mobile-menu/Main.vue";
// import DarkModeSwitcher from "@/components/dark-mode-switcher/Main.vue";
import SideMenuTooltip from "@/components/side-menu-tooltip/Main.vue";
import { linkTo, enter, leave } from "./index";
import context from "@cloudfun/core";

export default defineComponent({
  components: {
    TopBar,
    MobileMenu,
    // DarkModeSwitcher,
    SideMenuTooltip,
  },
  setup() {
    const application = context.current;
    const policy = application?.policy;
    const route = useRoute();
    const router = useRouter();
    const formattedMenu = ref([]);
    const sideMenu = computed(() => policy?.makeMenu() || []);

    watch(
      computed(() => route.path),
      () => {
        formattedMenu.value = $h.toRaw(sideMenu.value);
      }
    );

    const adminTitle = computed(
      () => application?.model.state.configuration.value.AdminTitle
    );

    onMounted(() => {
      cash("body")
        .removeClass("error-page")
        .removeClass("login")
        .addClass("app");
      formattedMenu.value = $h.toRaw(sideMenu.value);
      application?.model.dispatch("configuration/read");
    });

    return {
      formattedMenu,
      router,
      linkTo,
      enter,
      leave,
      adminTitle,
    };
  },
});
