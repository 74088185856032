import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

import SideMenu from "./layouts/side-menu/Main.vue";
import SimpleMenu from "./layouts/simple-menu/Main.vue";
import TopMenu from "./layouts/top-menu/Main.vue";
import Dashboard from "./views/dashboard/Main.vue";
// import Inbox from "./views/inbox/Main.vue";
// import FileManager from "./views/file-manager/Main.vue";
// import PointOfSale from "./views/point-of-sale/Main.vue";
// import Chat from "./views/chat/Main.vue";
// import Post from "./views/post/Main.vue";
// import CrudDataList from "./views/crud-data-list/Main.vue";
// import CrudForm from "./views/crud-form/Main.vue";
// import UsersLayout1 from "./views/users-layout-1/Main.vue";
// import UsersLayout2 from "./views/users-layout-2/Main.vue";
// import UsersLayout3 from "./views/users-layout-3/Main.vue";
// import ProfileOverview1 from "./views/profile-overview-1/Main.vue";
// import ProfileOverview2 from "./views/profile-overview-2/Main.vue";
// import ProfileOverview3 from "./views/profile-overview-3/Main.vue";
// import WizardLayout1 from "./views/wizard-layout-1/Main.vue";
// import WizardLayout2 from "./views/wizard-layout-2/Main.vue";
// import WizardLayout3 from "./views/wizard-layout-3/Main.vue";
// import BlogLayout1 from "./views/blog-layout-1/Main.vue";
// import BlogLayout2 from "./views/blog-layout-2/Main.vue";
// import BlogLayout3 from "./views/blog-layout-3/Main.vue";
// import PricingLayout1 from "./views/pricing-layout-1/Main.vue";
// import PricingLayout2 from "./views/pricing-layout-2/Main.vue";
// import InvoiceLayout1 from "./views/invoice-layout-1/Main.vue";
// import InvoiceLayout2 from "./views/invoice-layout-2/Main.vue";
// import FaqLayout1 from "./views/faq-layout-1/Main.vue";
// import FaqLayout2 from "./views/faq-layout-2/Main.vue";
// import FaqLayout3 from "./views/faq-layout-3/Main.vue";
import Login from "./views/login/Main.vue";
// import Register from "./views/register/Main.vue";
import ErrorPage from "./views/error-page/Main.vue";
// import UpdateProfile from "./views/update-profile/Main.vue";
// import ChangePassword from "./views/change-password/Main.vue";
// import RegularTable from "./views/regular-table/Main.vue";
// import Tabulator from "./views/tabulator/Main.vue";
// import Accordion from "./views/accordion/Main.vue";
// import Button from "./views/button/Main.vue";
// import Modal from "./views/modal/Main.vue";
// import Alert from "./views/alert/Main.vue";
// import ProgressBar from "./views/progress-bar/Main.vue";
// import Tooltip from "./views/tooltip/Main.vue";
// import Dropdown from "./views/dropdown/Main.vue";
// import Toast from "./views/toast/Main.vue";
// import Typography from "./views/typography/Main.vue";
// import Icon from "./views/icon/Main.vue";
// import LoadingIcon from "./views/loading-icon/Main.vue";
// import RegularForm from "./views/regular-form/Main.vue";
// import Datepicker from "./views/datepicker/Main.vue";
// import TailSelect from "./views/tail-select/Main.vue";
// import FileUpload from "./views/file-upload/Main.vue";
// import WysiwygEditor from "./views/wysiwyg-editor/Main.vue";
// import Validation from "./views/validation/Main.vue";
// import Chart from "./views/chart/Main.vue";
// import Slider from "./views/slider/Main.vue";
// import ImageZoom from "./views/image-zoom/Main.vue";
// import MemberDetail from "./views/MemberDetail/Main.vue";

import Configuration from "./views/configuration/Main.vue";
import Role from "./views/role/Main.vue";
import Banner from "./views/banner/Main.vue";
import Album from "./views/album/Main.vue";
import NewsCategory from "./views/news-category/Main.vue";
import News from "./views/news/Main.vue";
import Guild from "./views/guild/Main.vue";
import LinkCategory from "./views/link-category/Main.vue";
import Link from "./views/link/Main.vue";
import Member from "./views/member/Main.vue";
import User from "./views/user/Main.vue";
import AciotnLog from "./views/action-log/Main.vue";
import Industry from "./views/industry/Main.vue";
import About from "./views/about/Main.vue";
import ExchangeTopic from "./views/exchangeTopic/Main.vue";
import ExchangeReply from "./views/exchangeReply/Main.vue";
import ExchangeCategory from "./views/exchangeCategory/Main.vue";
import QuestionTopic from "./views/questionTopic/Main.vue";
import QuestionReply from "./views/questionReply/Main.vue";
import QuestionCategory from "./views/questionCategory/Main.vue";
import BusinessTopic from "./views/businessTopic/Main.vue";
import BusinessReply from "./views/businessReply/Main.vue";
import BusinessCategory from "./views/businessCategory/Main.vue";
import IndustryCategory from "./views/industryCategory/Main.vue";
import IndustryInfo from "./views/industryInfo/Main.vue";
import FileCategory from "./views/fileCategory/Main.vue";
import Files from "./views/files/Main.vue";
import Book from "./views/book/Main.vue";
import Order from "./views/order/DevMain.vue";
import Classification from "./views/classification/Main.vue";
import Dev from "./views/dev/Main.vue";
import ShoppingCart from "./views/shoppingCart/Main.vue";

const viewRotes: RouteRecordRaw[] = [
  {
    path: "dashboard",
    component: Dashboard,
  },
  {
    path: "test",
    // meta: { rule: "User" },
    component: Dev,
  },
  {
    path: "test2",
    // meta: { rule: "User" },
    component: () => import("./views/dev/ActionLog.vue"),
  },
  {
    path: "form-test",
    // meta: { rule: "User" },
    component: () => import("./views/dev/Form.vue"),
  },
  {
    path: "dropdown",
    // meta: { rule: "User" },
    component: () => import("./views/dev/DropDownBox.vue"),
  },
  {
    path: "dealer",
    component: () => import("./views/dealer/Main.vue"),
  },
  {
    path: "product",
    component: () => import("./views/product/Main.vue"),
  },
  {
    path: "select-box",
    component: () => import("./views/select-box/Main.vue"),
  },
  {
    path: "file-uploader",
    component: () => import("./views/file-uploader/Main.vue"),
  },
  {
    path: "dropdown-box",
    component: () => import("./views/dropdown-box/Main.vue"),
  },
  {
    path: "category-main",
    component: () => import("./views/category/Main.vue"),
  },
  {
    path: "category-sub",
    component: () => import("./views/category/sub-category/Main.vue"),
  },
  {
    path: "system/store-grade",
    component: () => import("./views/system/store-grade/Main.vue"),
  },
  {
    path: "system/color",
    component: () => import("./views/system/color/Main.vue"),
  },
  {
    path: "system/size",
    component: () => import("./views/system/size/Main.vue"),
  },
  {
    path: "system/country",
    component: () => import("./views/system/country/Main.vue"),
  },
  {
    path: "system/category/main",
    component: () => import("./views/system/category/Main.vue"),
  },
  {
    path: "system/category/sub",
    component: () => import("./views/system/category/Sub.vue"),
  },
  {
    path: "system/tag",
    component: () => import("./views/system/tag/Main.vue"),
  },
  {
    path: "system/warehouse",
    component: () => import("./views/system/warehouse/Main.vue"),
  },
  {
    path: "system/material",
    component: () => import("./views/system/material/Main.vue"),
  },
  {
    path: "shop-dev",
    component: () => import("./views/shoppingCart/DevMain.vue"),
  },
  // {
  //   path: "inbox",
  //   component: Inbox,
  // },
  // {
  //   path: "file-manager",
  //   component: FileManager,
  // },
  // {
  //   path: "point-of-sale",
  //   component: PointOfSale,
  // },
  // {
  //   path: "chat",
  //   component: Chat,
  // },
  // {
  //   path: "post",
  //   component: Post,
  // },
  // {
  //   path: "crud-data-list",
  //   component: CrudDataList,
  // },
  // {
  //   path: "crud-form",
  //   component: CrudForm,
  // },
  // {
  //   path: "users-layout-1",
  //   component: UsersLayout1,
  // },
  // {
  //   path: "users-layout-2",
  //   component: UsersLayout2,
  // },
  // {
  //   path: "users-layout-3",
  //   component: UsersLayout3,
  // },
  // {
  //   path: "profile-overview-1",
  //   component: ProfileOverview1,
  // },
  // {
  //   path: "profile-overview-2",
  //   component: ProfileOverview2,
  // },
  // {
  //   path: "profile-overview-3",
  //   component: ProfileOverview3,
  // },
  // {
  //   path: "wizard-layout-1",
  //   component: WizardLayout1,
  // },
  // {
  //   path: "wizard-layout-2",
  //   component: WizardLayout2,
  // },
  // {
  //   path: "wizard-layout-3",
  //   component: WizardLayout3,
  // },
  // {
  //   path: "blog-layout-1",
  //   component: BlogLayout1,
  // },
  // {
  //   path: "blog-layout-2",
  //   component: BlogLayout2,
  // },
  // {
  //   path: "blog-layout-3",
  //   component: BlogLayout3,
  // },
  // {
  //   path: "pricing-layout-1",
  //   component: PricingLayout1,
  // },
  // {
  //   path: "pricing-layout-2",
  //   component: PricingLayout2,
  // },
  // {
  //   path: "invoice-layout-1",
  //   component: InvoiceLayout1,
  // },
  // {
  //   path: "invoice-layout-2",
  //   component: InvoiceLayout2,
  // },
  // {
  //   path: "faq-layout-1",
  //   component: FaqLayout1,
  // },
  // {
  //   path: "faq-layout-2",
  //   component: FaqLayout2,
  // },
  // {
  //   path: "faq-layout-3",
  //   component: FaqLayout3,
  // },
  // {
  //   path: "update-profile",
  //   component: UpdateProfile,
  // },
  // {
  //   path: "change-password",
  //   component: ChangePassword,
  // },
  // {
  //   path: "regular-table",
  //   component: RegularTable,
  // },
  // {
  //   path: "tabulator",
  //   component: Tabulator,
  // },
  // {
  //   path: "accordion",
  //   component: Accordion,
  // },
  // {
  //   path: "button",
  //   component: Button,
  // },
  // {
  //   path: "modal",
  //   component: Modal,
  // },
  // {
  //   path: "alert",
  //   component: Alert,
  // },
  // {
  //   path: "progress-bar",
  //   component: ProgressBar,
  // },
  // {
  //   path: "tooltip",
  //   component: Tooltip,
  // },
  // {
  //   path: "dropdown",
  //   component: Dropdown,
  // },
  // {
  //   path: "toast",
  //   component: Toast,
  // },
  // {
  //   path: "typography",
  //   component: Typography,
  // },
  // {
  //   path: "icon",
  //   component: Icon,
  // },
  // {
  //   path: "loading-icon",
  //   component: LoadingIcon,
  // },
  // {
  //   path: "regular-form",
  //   component: RegularForm,
  // },
  // {
  //   path: "datepicker",
  //   component: Datepicker,
  // },
  // {
  //   path: "tail-select",
  //   component: TailSelect,
  // },
  // {
  //   path: "file-upload",
  //   component: FileUpload,
  // },
  // {
  //   path: "wysiwyg-editor",
  //   component: WysiwygEditor,
  // },
  // {
  //   path: "validation",
  //   component: Validation,
  // },
  // {
  //   path: "chart",
  //   component: Chart,
  // },
  // {
  //   path: "slider",
  //   component: Slider,
  // },
  // {
  //   path: "image-zoom",
  //   component: ImageZoom,
  // },
  {
    path: "configuration",
    component: Configuration,
    meta: { rule: "Configuration" },
  },
  {
    path: "role",
    component: Role,
    meta: { rule: "Role" },
  },
  {
    path: "banner",
    component: Banner,
    meta: { rule: "Banner" },
  },
  {
    path: "album",
    component: Album,
    meta: { rule: "Album" },
  },
  {
    path: "news-category",
    component: NewsCategory,
    meta: { rule: "NewsCategory" },
  },
  {
    path: "news",
    component: News,
    meta: { rule: "News" },
  },
  {
    path: "guild",
    component: Guild,
    meta: { rule: "Organization" },
  },
  {
    path: "link-category",
    component: LinkCategory,
    meta: { rule: "RelationLinkCategory" },
  },
  {
    path: "link",
    component: Link,
    meta: { rule: "RelationLink" },
  },
  {
    path: "member",
    component: Member,
    meta: { rule: "Member" },
  },
  {
    path: "user",
    component: User,
    meta: { rule: "User" },
  },
  {
    path: "action-log",
    component: AciotnLog,
    meta: { rule: "ActionLog" },
  },
  {
    path: "topic",
    component: () => import("./views/topic/Main.vue"),
  },
  {
    path: "topic/detail",
    component: () => import("./views/topic/Detail.vue"),
  },

  // {
  //   path: "organization",
  //   component: Organization,
  //   meta: { rule: 'About2' }
  // },
  // {
  //   path: "privacy",
  //   component: Privacy,
  //   meta: { rule: 'PrivacyPolicy' }
  // },
  // {
  //   path: "task",
  //   component: Task,
  //   meta: { rule: 'About1' }
  // },
  {
    path: "industry",
    component: Industry,
    meta: { rule: "About" },
  },
  {
    path: "about",
    component: About,
    meta: { rule: "About" },
  },
  {
    path: "exchangeTopic",
    component: ExchangeTopic,
    meta: { rule: "Forum" },
  },
  {
    path: "exchangeReply",
    component: ExchangeReply,
    meta: { rule: "Forum" },
  },
  {
    path: "exchangeCategory",
    component: ExchangeCategory,
    meta: { rule: "Forum" },
  },
  {
    path: "questionTopic",
    component: QuestionTopic,
    meta: { rule: "Forum" },
  },
  {
    path: "questionReply",
    component: QuestionReply,
    meta: { rule: "Forum" },
  },
  {
    path: "questionCategory",
    component: QuestionCategory,
    meta: { rule: "Forum" },
  },
  {
    path: "businessTopic",
    component: BusinessTopic,
    meta: { rule: "Forum" },
  },
  {
    path: "businessReply",
    component: BusinessReply,
    meta: { rule: "Forum" },
  },
  {
    path: "businessCategory",
    component: BusinessCategory,
    meta: { rule: "Forum" },
  },
  {
    path: "industryCategory",
    component: IndustryCategory,
    meta: { rule: "IndustryCategory" },
  },
  {
    path: "industryInfo",
    component: IndustryInfo,
    meta: { rule: "Industry" },
  },
  {
    path: "fileCategory",
    component: FileCategory,
    meta: { rule: "FileCategory" },
  },
  {
    path: "downloadManagement",
    component: Files,
    meta: { rule: "Files" },
  },
  {
    path: "book",
    component: Book,
    meta: { rule: "Book" },
  },
  {
    path: "order",
    component: Order,
  },
  {
    path: "shoppingCart",
    component: ShoppingCart,
  },
  {
    path: "classification",
    component: Classification,
    meta: { rule: "Classification" },
  },
];

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    component: SideMenu,
    redirect: "dashboard",
    children: viewRotes,
  },
  {
    path: "/simple-menu",
    component: SimpleMenu,
    redirect: "dashboard",
    children: viewRotes,
  },
  {
    path: "/top-menu",
    component: TopMenu,
    redirect: "dashboard",
    children: viewRotes,
  },
  // {
  //   path: "/member/:MemberNumber",
  //   name: "MemberDetail",
  //   component: MemberDetail,
  // },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  // {
  //   path: "/register",
  //   name: "register",
  //   component: Register,
  // },
  {
    path: "/error-page",
    name: "error-page",
    component: ErrorPage,
  },
  {
    path: "/:pathMatch(.*)*",
    component: ErrorPage,
  },
];

export default createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { left: 0, top: 0 };
  },
});
