import { Application, MessageType, Policy } from "@cloudfun/core";
import model from "./models";
import router from "./router";
import sitemap from "./sitemap";

import App from "./App.vue";
import globalComponents from "./global-components";
import utils from "./utils";
import "./libs";
import Toastify from "toastify-js";

import "xe-utils";
import VXETable from "vxe-table";
import "vxe-table/lib/style.css";

// Midone Theme
import "./assets/sass/app.scss";
// Devextreme Theme
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";

import i18n from "./locales/index";

const app = new Application(
  App,
  model,
  new Policy(model, { router, sitemap, loginRoute: "/login" }),
  i18n
);
// 將 JS 攔截到的錯誤轉給 CloudfunVue Messenger
window.onerror = (message, source, lineno, colno) => {
  if (message === "ResizeObserver loop limit exceeded") return false;
  app.send("log", {
    createdTime: new Date(),
    type: MessageType.Error,
    content: app.user
      ? `[${app.user.Name}] \r\n${message}\r\n    at ${source}:${lineno}:${colno}`
      : `\r\n${message}\r\n    at ${source}:${lineno}:${colno}`,
  });
  return true;
};

// 將 Vue.js 攔截到的錯誤轉給 CloudfunVue Messenger
app.config.errorHandler = (error: any, instance, info) => {
  let content = app.user ? `[${app.user.Name}] ${info}: ` : `${info}: `;
  if (typeof error === "string") content += error;
  else content += `${error.message}\r\n${error.stack}`;
  console.log(error);
  app.send("log", {
    createdTime: new Date(),
    type: MessageType.Error,
    content,
  });
};

// 設定信差中介程序
CloudFun.setMiddlewares({
  info: (message) =>
    Toastify({
      text: `${
        typeof message === "string"
          ? "Information"
          : message.subject || "Information"
      }: ${typeof message === "string" ? message : message.content}`,
      close: true,
      gravity: "bottom",
      position: "left",
      backgroundColor: "#10b981",
      stopOnFocus: true,
    }).showToast(),
  warning: (message) =>
    Toastify({
      text: `${
        typeof message === "string" ? "Warning" : message.subject || "Warning"
      }: ${typeof message === "string" ? message : message.content}`,
      close: true,
      gravity: "bottom",
      position: "left",
      backgroundColor: "#f5970f",
      stopOnFocus: true,
    }).showToast(),
  error: (message) =>
    Toastify({
      text: `${
        typeof message === "string" ? "Error" : message.subject || "Error"
      }: ${typeof message === "string" ? message : message.content}`,
      duration: -1,
      close: true,
      gravity: "bottom",
      position: "left",
      backgroundColor: "#ef4444",
      stopOnFocus: true,
    }).showToast(),
});

globalComponents(app);
utils(app);

app.use(VXETable);
VXETable.setup({ i18n: (key, args) => app.i18n.global.t(key, args) });

app.run("#app");