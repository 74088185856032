<template>
  <dx-load-panel
    :visible="config.visible"
    :show-indicator="config.showIndicator"
    :show-pane="config.showPane"
    :shading="config.shading"
    :close-on-outside-click="config.closeOnOutsideClick"
    :on-shown="config.onShown"
    :on-hidden="config.onHidden"
    shading-color="rgba(0,0,0,0.4)"
    :position="config.position"
  />
</template>

<script lang="ts">
import { defineComponent, reactive } from "vue";
import { DxLoadPanel } from "devextreme-vue/load-panel";
import { cloneDeep } from "../../assets/scripts/cloudfun/clone";
export default defineComponent({
  components: {
    DxLoadPanel,
  },
  props: {
    options: {
      type: Object,
    },
  },
  setup(props) {
    const defaultOptions = reactive({
      visible: false,
      showIndicator: true,
      shading: true,
      showPane: true,
      closeOnOutsideClick: false,
    });
    // position: { of: '#employee' },

    const config = cloneDeep({}, defaultOptions, props.options);
    console.log("panel config", config);
    return {
      defaultOptions,
      config,
    };
  },
});
</script>

<style scoped></style>
