<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">相簿管理</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <button v-show="!isEditing" class="button text-white bg-theme-1 shadow-md mr-2 flex items-center" @click="grid.addNewRow">
          <FontAwesome icon="plus" type="fas" class="w-4 h-4 mr-1" />
          新增
        </button>
        <div v-show="!grid.isEditing && grid.isRowSelected" id="batch-dropdown" class="dropdown ml-auto mr-2 sm:ml-0">
          <button
            class="dropdown-toggle button px-2 box bg-blue-500 text-white flex items-center"
          >
            <FontAwesome icon="tasks" type="fas" class="w-4 h-4 mr-1" />
            群組操作
          </button>
          <div class="dropdown-box">
            <div class="dropdown-box__content box dark:bg-dark-1 p-2 w-32">
              <span 
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.removeSelectedRows"
              >
                <FontAwesome icon="trash" type="fas" class="w-4 h-4 mr-2" /> 刪除
              </span>
              <!-- <span 
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onPrint({ mode: 'selected' })"
              >
                <FontAwesome icon="print" type="fas" class="w-4 h-4 mr-2" /> 列印
              </span> -->
              <span 
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onExport({ type: 'csv', mode: 'selected' })"
              >
                <FontAwesome icon="file-csv" type="fas" class="w-4 h-4 mr-2" /> 匯出 CSV
              </span>
              <!-- <span 
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onExport({ type: 'html', mode: 'selected' })"
              >
                <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" /> 匯出 HTML
              </span> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <div class="xl:flex sm:mr-auto">
          <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
            <input
              type="text"
              class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border"
              v-model.trim="grid.keyword"
              placeholder="請輸入關鍵字"
              @keyup.enter="grid.refresh"
            />
          </div>
          <div class="mt-2 xl:mt-0">
            <button
              type="button"
              class="button w-full sm:w-16 bg-theme-1 text-white"
              @click="grid.refresh"
            >
              搜尋
            </button>
          </div>
        </div>
        <div class="flex mt-2 sm:mt-0">
          <!-- <button
            class="button w-1/2 sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300"
            @click="grid.onPrint({ mode: 'curret' })"
          >
            <FontAwesome icon="print" class="w-4 h-4 mr-2" /> 列印
          </button> -->
          <div class="dropdown w-1/2 sm:w-auto ml-2">
            <button
              class="dropdown-toggle button w-full sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300"
            >
              <FontAwesome icon="file-export" class="w-4 h-4 mr-2" /> 匯出
              <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
            </button>
            <div class="dropdown-box">
              <div class="dropdown-box__content box dark:bg-dark-1 p-2">
                <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="grid.onExport({ type: 'csv' })"
                >
                  <FontAwesome icon="file-csv" class="w-4 h-4 mr-2" /> CSV
                </a>
                <!-- <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="grid.onExport({ type: 'html' })"
                >
                  <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" /> HTML
                </a> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden mt-2">
        <grid 
          ref="grid" 
          v-bind="gridOptions" 
          @addNewRow="(row, callback) => { row.Published = false; row.Ordinal = 0; callback(); }"
          @edit="onGridEdit"
          @modalResize="onGridModalResize"
          @removeSelectedRows="onGridRemoveSelectedRows" 
        >
          <template #modal="{ row, submit, reset }">
            <vxe-form v-bind="formOptions" :data="row" @reset="reset" @submit="submit">
              <template #form-pictures>
                <div class="ml-3">
                  <slider-editor 
                    ref="sliderEditor"
                    :navigation="true"
                    :width="sliderWidth" 
                    :filePostAction="`${baseUrl}/api/files`"
                    :formOptions="pictureFormOptions"
                    v-model="row.Pictures"
                    @add="onSliderEdit"
                    @edit="onSliderEdit"
                    @save="onSliderSave"
                    @remove="onSliderRemove"
                  />
                </div>
              </template>
            </vxe-form>
          </template>
        </grid>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<style scoped>
  .swiper-container {
    padding-top: 5px;
    padding-bottom: 30px;
  }
</style>

<script>
import CloudFun, { defineComponent, ref } from "@cloudfun/core";
import Grid from "@/cloudfun/components/Grid.vue"
import SliderEditor from "@/cloudfun/components/SliderEditor.vue"
import formatDate from 'xe-utils/toDateString';

export default defineComponent({
  components:{
    SliderEditor,
    Grid,
  },
  setup() {
    const model = CloudFun.current?.model;

    const grid = ref({});

    var printColumns = [
      { field: 'Number' }, 
      { field: 'Title' },
      { field: 'Published' },
      { field: 'StartDate' },
      { field: 'EndDate' },
      { field: 'Ordinal' },
    ]

    const gridOptions = {
      title: '相簿',
      toolbarConfig: {
        custom: true,
        refresh: true,
      },
      sortConfig: { defaultSort: { field: 'Ordinal', orderby: 'asc' } },
      printConfig: { sheetName: '活動相簿清單', columns: printColumns, modes: [ 'current', 'selected', 'all' ] },
      exportConfig: { filename: '活動相簿清單', type: 'csv', types: [ 'html', 'csv' ], mode: 'all', modes: [ 'current', 'selected', 'all' ], columns: printColumns },
      columns: [
        { field: 'Number', title: '編號', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'Title', title: '標題', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'Published', title: '已發佈', showHeaderOverflow: true, showOverflow: true, sortable: true, formatter: ({ cellValue }) => cellValue ? '是' : '否' },
        { field: 'StartDate', title: '開始日期', showHeaderOverflow: true, showOverflow: true, sortable: true, formatter: ({ cellValue }) => formatDate(cellValue, 'yyyy/MM/dd') },
        { field: 'EndDate', title: '結束日期', showHeaderOverflow: true, showOverflow: true, sortable: true, formatter: ({ cellValue }) => formatDate(cellValue, 'yyyy/MM/dd') },
        { field: 'Ordinal', title: '排序', showHeaderOverflow: true, showOverflow: true, sortable: true }     
      ],
      promises: {
        query: model ? (params) => model.dispatch('pictureAlbum/query', params) : undefined,
        queryAll: model ? () => model.dispatch('pictureAlbum/query') : undefined,
        save: model ? (params) => model.dispatch('pictureAlbum/save', params) : undefined
      },
      modalConfig: { height: 550 }
    };
    const formOptions = {
      titleWidth: 80, 
      titleAlign: 'right',
      items: [
        {
          field: 'Number', title: '編號', span: 16, 
          itemRender: { name: '$input', props: { placeholder: '系統自動產生', readonly: true } },
        }, {
          field: 'Ordinal', title: '排序', span: 8, titleWidth: 60,
          itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字' } },
        }, {
          field: 'Title', title: '標題', span: 16, 
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true } },
        }, {
          field: 'Published', title: '已發佈', span: 8, titleWidth: 60,
          itemRender: { name: '$select', options: [ { value: false, label: '否' }, { value: true, label: '是' }] },
        }, {
          field: 'StartDate', title: '開始日期', span: 12, 
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期', clearable: true } },
        }, {
          field: 'EndDate', title: '結束日期', span: 12, 
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期', clearable: true } },
        }, {
          span: 24, slots: { default: 'form-pictures' }
        }, {
          span: 24, align: "right", 
          itemRender: { name: '$buttons', children: [{ props: { type: 'submit', content: '確定', status: 'primary' } }, { props: { type: 'reset', content: '重置' } }] }
        }
      ], 
      rules: { 
        Title: [{ type: 'string', max: 128, required: true }],
      }
    };

    const sliderWidth = ref('100%');
    const pictureFormOptions = {
      titleWidth: 40, 
      titleAlign: 'right',
      items: [
        {
          field: 'Name', title: '名稱', span: 14, 
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true } },
        }, {
          field: 'Ordinal', title: '排序', span: 10, titleWidth: 40,
          itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字' } },
        }, {
          field: 'Remark', title: '備註', span: 24, 
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true } },
        }, {
          span: 24, align: "right", 
          itemRender: { name: '$buttons', children: [{ props: { type: 'submit', content: '確定', status: 'primary' } }, { props: { type: 'reset', content: '清除' } }] }
        }
      ],
      rules: { 
        Name: [{ type: 'string', max: 128 }],
      }
    };

    return {
      grid,
      gridOptions,
      formOptions,
      sliderWidth,
      pictureFormOptions,
      baseUrl: process.env.VUE_APP_BACKEND_URL,
    };
  },
  methods: {
    onGridModalResize(size) {
      this.sliderWidth = `${size.width-100}px`;
    },
    async onGridEdit(row, callback) {
      const entity = row.Id ? await this.$model.dispatch('pictureAlbum/find', row.Id) : undefined;
      if (entity) Object.assign(row, entity);
      if (row.Pictures) row.Pictures = row.Pictures.sort((a, b) => a.Ordinal - b.Ordinal);
      callback();
    },
    onGridRemoveSelectedRows(rows, callback) {
      cash('#batch-dropdown').dropdown('hide');
      callback();
    },
    onSliderEdit(image, callback) {
      image.AlbumId = this.grid.editingRow.Id;
      callback();
    },
    async onSliderRemove(image, callback) {
      await this.$model.dispatch('picture/delete', image.Id);
      callback();
    },
    async onSliderSave(params, callback) {
      await this.$model.dispatch('picture/save', params).then(
        () => {
          this.$model.dispatch('pictureAlbum/find', this.grid.editingRow.Id).then(
            (entity) => { 
              if (entity.Pictures) entity.Pictures = entity.Pictures.sort((a, b) => a.Ordinal - b.Ordinal);
              this.$refs.sliderEditor.reload(entity.Pictures.sort((a, b) => a.Ordinal - b.Ordinal));
            }, 
            reason => { CloudFun.send('error', { subject: '重載失敗', content: reason }) }
          );
        },
        reason => { CloudFun.send('error', { subject: '保存失敗', content: reason }) }
      );
      callback();
    },
  },
});
</script>
