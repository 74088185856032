
/* eslint-disable */
import { defineComponent, ref, provide, onMounted, reactive } from "vue";
import CloudFun, { Model } from "@cloudfun/core";
import PieChart from "@/components/pie-chart/Main.vue";
import LineChart from "@/components/line-chart/Main.vue";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import router from "@/router";

export default defineComponent({
  components: {
    PieChart,
    LineChart,
    Loading
  },
  setup() {
    if (!CloudFun.user.Id) router.push('/login');
    const salesReportFilter = ref("");
    var load = reactive({
      isLoading: false,
      fullPage: false,
    });     
    var gaData = reactive<any>({});

    var pieChart = reactive({
      width: {
        type: Number,
        default: 0
      },
      height: {
        type: Number,
        default: 0
      },      
      data:{
        labels: ["新訪客率", "舊訪客率"],
        datasets: [{
          data: [50, 50],
          backgroundColor: ["#26a1ff", "#ff2626"],
          hoverBackgroundColor: ["#26a1ff", "#ff2626"],
          borderWidth: 5,
          borderColor: cash("html").hasClass("dark") ? "#303953" : "#fff"
        }]
      }
    });

    var lineChart = reactive({
      width: {
        type: Number,
        default: 0
      },
      height: {
        type: Number,
        default: 0
      },      
      data:{
        labels: [],
        datasets: [
          {
            label: "人數",
            data: [],
            borderWidth: 2,
            borderColor: "#3160D8",
            backgroundColor: "transparent",
            pointBorderColor: "transparent",
          },
        ],
      }
    });
        

    const importantNotesRef = ref<HTMLElement>();

    provide("bind[importantNotesRef]", (el: HTMLElement) => {
      importantNotesRef.value = el;
    });

    const prevImportantNotes = () => {
      const el: any = importantNotesRef.value;
      el.tns.goTo("prev");
    };
    const nextImportantNotes = () => {
      const el: any = importantNotesRef.value;
      el.tns.goTo("next");
    };

    const baseUrl = `${process.env.VUE_APP_BACKEND_URL}`;    
    const getGoogleAnalytics = () => {
      return new Promise((resolve, reject) => {
        Model.createHttpClient(baseUrl).get(`${baseUrl}/api/System/GoogleAnalytics`, {})
          .then(function (response: any) {
            resolve(response.payload)
          })
          .catch(function (error) {
            reject(error)
        });
      });
    };

    onMounted(() => {
      // if(CloudFun.user.Id){
      //   load.isLoading = true;
      //   getGoogleAnalytics().then((data: any) =>{  
      //     Object.assign(gaData, data)
      //     gaData.TotalCountryUsers = data.UsersByCountry.map((e: any) => e.Value).reduce((a: number ,b: number) => a + b);
      //     gaData.TotalLanguageUsers = data.UsersByLanguage.map((e: any) => e.Value).reduce((a: number ,b: number) => a + b);
      //     pieChart.data.labels = ["新訪客率", "舊訪客率"];
      //     pieChart.data.datasets[0].data = [data.PercentNewSessions, 100-data.PercentNewSessions];
      //     lineChart.data.labels = data.UsersByDate.map((e: any) => { return e.Name });
      //     lineChart.data.datasets[0].data = data.UsersByDate.map((e: any)=> { return e.Value });        
      //   }).catch((error) => CloudFun.send('error', { subject: 'GA資料取得失敗', content: error.message })
      //   ).finally(() => {
      //     load.isLoading = false          
      //   });
      // }
    })

    return {
      gaData,
      pieChart,
      lineChart,
      salesReportFilter,
      prevImportantNotes,
      nextImportantNotes,
      load,      
    };
  },
});
