
/* eslint-disable */

import { onMounted } from "vue";
import CloudFun, { Model, defineComponent, ref, reactive, computed, Sorting, SortOrder, Condition, Operator, LogicalConnective } from "@cloudfun/core";
import { VxeColumnPropTypes, VxeGridInstance, VxeGridProps, VxeFormPropTypes, Column, VxeModalInstance, VxeModalProps } from "vxe-table";
import FileUpload from 'vue-upload-component';

export default defineComponent({
  components:{    
    FileUpload,    
  },
  setup() {
    const uploader = ref({});
    const files = ref([]);    
    const model = CloudFun.current?.model;
    const grid = ref({} as VxeGridInstance);
    const modal = ref({} as VxeModalInstance);
    const loading = ref(false);
    const editingRow = ref(null);
    const keyword = ref("");    
    const customFilters = ref<Condition[]>([]);
    const isRowSelected = computed(() => (grid.value.getRadioRecord && grid.value.getRadioRecord()) || (grid.value.getCheckboxRecords && grid.value.getCheckboxRecords().length > 0));
    const isEditing = computed(() => editingRow.value != null && grid.value.isActiveByRow(editingRow.value));
    var printColumns = [
      { field: 'Category.Name' }, 
      { field: 'Name' },
      { field: 'PublishedString' },
      { field: 'Ordinal' },
    ]

    const gridOptions = reactive({
      keepSource: true,
      round: true,
      border: true,
      stripe: true,
      highlightHoverColumn: true,
      highlightHoverRow: true,
      resizable: true,
      loading: false,
      customConfig: { storage: true },
      filterConfig: { remote: true },
      sortConfig: { remote: true, multiple: true },
      //editConfig: { trigger: 'manual', mode: 'row', showIcon: false, autoClear: false },
      pagerConfig: { currentPage: 1, pageSize: 10, pageSizes: [5, 10, 20], layouts: [ 'PrevJump', 'PrevPage', 'Jump', 'PageCount', 'NextPage', 'NextJump', 'Sizes', 'Total' ] },
      printConfig: { sheetName: '相關連結清單', columns: printColumns, modes: [ 'current', 'selected', 'all' ] },
      exportConfig: { filename: '相關連結清單', type: 'csv', types: [ 'html', 'csv' ], mode: 'all', modes: [ 'current', 'selected', 'all' ], columns: printColumns },
      columns: [
        { type: "checkbox", width: 35, fixed: 'left', resizable: false },        
        { field: 'Category.Name', title: '分類名稱' },
        { 
          field: "Name", title: "標題", showHeaderOverflow: true, showOverflow: true, sortable: true,
          filters: [{ checked: false, label: '包含' }],
          slots: { filter: 'name_filter' }
        },
        { field: "PublishedString", title: "發佈", showHeaderOverflow: true, showOverflow: true, resizable: false }, 
        { field: "Ordinal", title: "排序", showHeaderOverflow: true, showOverflow: true, resizable: false },                     
        { title: "操作", width: 100, fixed: 'right', align: 'center', resizable: false, slots: { default: 'operate' } },
      ],
      proxyConfig: {
        message: false,
        sort: true, 
        filter: true, 
        props: { result: 'data', total: 'totalCount' }, 
        ajax: {
          query: (params) => {    
            //params.sorts.push({ column: Column.prototype, order: 'desc', sortBy: 'Number', property:'Number' });    
            
            const queryParams: { page: number, pageSize: number, keyword: string, sortings?: Sorting[], condition: Condition } = { 
              page: params.page.currentPage, 
              pageSize: params.page.pageSize, 
              keyword: keyword.value,
              sortings: params.sorts.filter(e => e.property).map(e => new Sorting(e.property, e.order === 'desc' ? SortOrder.Descending : SortOrder.Ascending)), // sorts
              condition: new Condition(customFilters.value) // custom filters
            };
            
            // filters
            const filters = params.filters ? params.filters.filter(e => e.values.length) : null;
            if (filters && filters.length) {
              const gridCondition = new Condition();
              // colum filters
              filters.forEach(filter => { 
                const columnCondition = new Condition();
                filter.values.forEach(subFilter => {
                  if (subFilter && subFilter instanceof Condition) {
                    const condition = subFilter as Condition;
                    if (condition.connective === LogicalConnective.And) columnCondition.and(condition);
                    else columnCondition.or(condition);
                  }
                });
                gridCondition.and(columnCondition);
              });
              queryParams.condition.and(gridCondition);
            }
            return new Promise((resolve, reject) => model?.dispatch('link/load', queryParams).then(
              payload => {
                resolve(payload)
              },
              reason => {               
                CloudFun.send('error', { subject: '讀取失敗', content: reason })
                reject(reason);
              }
            ));
          },
          delete: (params) => {
            const deleteParams = { deleteRows: params.body.removeRecords };
            return new Promise((resolve, reject) => model?.dispatch('link/save', deleteParams).then(
              payload => resolve(payload),
              reason => {
                CloudFun.send('error', { subject: '刪除失敗', content: reason })
                reject(reason);
              }
            ));
          },
          save: (params) => {
            const saveParams = { insertRows: params.body.insertRecords, updateRows: params.body.updateRecords, deleteRows: params.body.removeRecords };
            return new Promise((resolve, reject) => model?.dispatch('link/save',  saveParams).then(
              payload => { 
                editingRow.value = null;
                resolve(payload)
              },
              reason => {
                CloudFun.send('error', { subject: '保存失敗', content: reason })
                reject(reason);
              }
            ));
          }
        }
      }
    } as VxeGridProps);

    //modal 執行 新增or修改
    const submitSave = () =>{
      var obj = files.value;
      const formData = new FormData();
      if(obj.length > 0){
        formData.append("file", obj[0]['file']);        
      }
      formData.append("json", JSON.stringify(form.formData));

      var method = form.model == 'insert' ? 'insert' : 'update';
      gridOptions.loading = true;
      
      model?.dispatch(`link/${method}`, formData).then(
        paylod => {
          //var sorting = new Sorting('CreatedTime', 1);
          grid.value.commitProxy('query').finally(() => {  });
          CloudFun.send('info', { subject: method == 'insert' ? '新增成功' : '更新成功', content: method == 'insert' ? '相關連結新增完成' : '相關連結更新完成' })
        },
        failure => CloudFun.send('error', { subject: '操作失敗！', content: failure })
      ).finally(() => { 
        form.showEdit = false; 
        gridOptions.loading = false;       
      });
    }


    const form = reactive({
      model: 'insert',   
      CityList: [],
      DistrictsList: [],
      CategoryList: [],
      selectRow: true,
      showEdit: false,
      formData: {
        Id: 0,
        CreatedTimeString: '',
        CreatedTime:'',
        Name: '',
        Published: false,
        Ordinal: 0,
        ClickCount: 0,         
        Category: '',
        CategoryId: 0,
        LinkUrl: '',
        PublishedString: '',
        Operator: '',
        OperatorId: '',        
        Picture: {
          Uri: '',
        }
      },
      formRules: {
        Name: [
          { required: true, message: '請輸入標題' },          
        ],
        CategoryId: [
          { required: true, message: '請輸入分類名稱' },          
        ],
        LinkUrl: [
          { required: true, message: '請輸入連結網址', pattern: new RegExp("(https?:\\/\\/[\\w-\\.]+(:\\d+)?(\\/[~\\w\\/\\.]*)?(\\?\\S*)?(#\\S*)?)") },          
        ],
        Published: [
          { required: true, message: '請輸入發佈' }
        ],
      } as VxeFormPropTypes.Rules,
      formItems: [        
        { field: 'Name', title: '標題', span: 12, itemRender: { name: '$input', props: { placeholder: '標題' } } },
        { field: 'CategoryId', title: '分類', span: 12, itemRender: { name: '$select', options:[] } },
        { field: 'Published', title: '是否發佈', span: 12, itemRender: { name: '$radio', options: [ { label: '否', value: false }, { label: '是', value: true }] } },
        { field: 'Ordinal', title: '排序', span: 12, itemRender: { name: '$input', props: { type: 'number', placeholder: '排序' } } },
        { field: 'ClickCount', title: '點擊次數', span: 12, itemRender: { name: '$input', props: { type: 'number', placeholder: '點擊次數' } } },
        { field: 'LinkUrl', title: '連結網址', span: 24, itemRender: { name: '$input', props: { placeholder: '連結網址' } } },
        { align: 'right', span: 24, titleAlign: 'left', itemRender: { name: '$buttons', children: [{ props: { type: 'submit', content: '確認', status: 'primary' } } , { props: { type: 'reset', content: '重置' } }] } } //, events: { click: save }
      ] as VxeFormPropTypes.Items
    });

    const baseUrl = `${process.env.VUE_APP_BACKEND_URL}`;    
    const getCategories = () => {
        return new Promise((resolve, reject) => {
          Model.createHttpClient(baseUrl).get(`${baseUrl}/api/RelationLinkCategory`, { params: { keyword: null, orderColumn:'CreatedTime', descending: true, skipRows: null, takeRows: null, simple: true  } })
          .then(function (response: any) {            
            resolve(response.payload)
          })
          .catch(function (error) {
            reject(error)
            
          });
      });
    };

    const onResize = () => {      
      if(form.formData.Picture && form.formData.Picture.Uri) {
        //setTimeout(() => {
          // console.log('test')
          // var ins = (document.getElementsByClassName('vxe-modal--box')[0])
          // var top = (window.screen.availHeight - ins.clientHeight) / 2;
          // console.log(window.screen.availHeight, ins.clientHeight)
          // console.log(top)
          // modal.value.setPosition(top)
        //}, 200); 
      }
    } 

    onMounted(() => {
      getCategories().then((data: any) =>{  
          var item = form.formItems[1]; 
          if (item.itemRender && data.length > 0) {
            form.CategoryList = data;
            item.itemRender.options = data;
            //console.log(item.itemRender.options, form.CategoryList[0]['value'])            
          }
      });
    })

    return {
      files,
      modal,
      uploader,      
      form,
      grid,
      gridOptions,
      loading,
      isEditing,
      editingRow,
      isRowSelected,
      keyword,
      customFilters,
      submitSave,
      getCategories,
      onResize
    };
  },
  methods: {
    reset() { 
      this.form.formData.Id = 0      
      this.form.formData.Picture = { Uri:'' }
      this.form.formData.CreatedTimeString = ''
      this.form.formData.CreatedTime = ''
      this.form.formData.Name = ''
      this.form.formData.Published = false
      this.form.formData.Ordinal = 0
      this.form.formData.ClickCount = 0      
      this.form.formData.Category = ''
      this.form.formData.CategoryId = this.form.CategoryList.length > 0 ? this.form.CategoryList[0]['value'] : 0;
      this.form.formData.Name = ''     
      this.form.formData.Operator = ''
      this.form.formData.OperatorId = ''
      this.form.formData.LinkUrl = ''
      if(this.files.length > 0) this.files = []
    },
    edit(row: any) {      
      this.$model.dispatch('link/find', row.Id).then(
        paylod => {
          Object.assign(this.form.formData, paylod)
          if(this.form.formData.Picture.Uri) {
            setTimeout(() => {
              this.form.formData.Picture.Uri = this.form.formData.Picture.Uri + "?" + new Date().getTime();   
            }, 200);
          }
          this.form.model = 'edit';
          this.form.showEdit = true;
        },
        failure => this.$send('error', { subject: '操作失敗！', content: failure.message })
      );
    },
    addNewRow() {
      this.reset();
      this.form.model = 'insert';
      this.form.showEdit = true;      
    },
    save(row?: any) {
      const { insertRecords, updateRecords, removeRecords } = this.grid.getRecordset();
      let needSave = insertRecords.length || updateRecords.length || removeRecords.length;
      needSave &&= row && (insertRecords.some(e => e._XID === row._XID) || updateRecords.some(e => e._XID === row._XID) || removeRecords.some(e => e._XID === row._XID));   
      const isRowEditing = row && this.editingRow === row;
      if (needSave) {
        this.grid.commitProxy('save').then(
          () => {
            if (isRowEditing && this.editingRow === row) this.edit(row);
            else this.editingRow = null;
          }
        );
      } else if (!row || row === this.editingRow) this.editingRow = null;
    },
    remove(row: any) {
      if (row && confirm("確定要刪除此筆資料嗎?")) {
        this.gridOptions.loading = true;
        this.$model.dispatch('link/delete', row.Id).then(
          () => { this.grid.commitProxy('query').finally(() => { this.gridOptions.loading = false; }); },
          failure => this.$send('error', { subject: '相關連結刪除失敗', content: failure.message })
        );
      }
    },
    removeSelectedRows() {
      cash('#batch-dropdown').dropdown('hide');
      const rows = this.grid.getCheckboxRecords();
      if (rows && rows.length && confirm("確定要刪除已被選擇的資料嗎?")) {
        this.grid.commitProxy('delete');
      }
    },
    changeFilterEvent(event: Event, option: VxeColumnPropTypes.Filter, panel: any, toogleChecked?: boolean) {
      if (toogleChecked) option.checked = !option.checked;
      if (option.checked && option.data) {
        if (!option.value) option.value = new Condition('Name', Operator.Contains);
        option.value.value = option.data;
      } else option.value = option.data = undefined;
      if (toogleChecked || option.checked) panel.changeOption(event, !!option.data, option)
    },    
    async onExport(type: 'csv' | 'html', mode: 'current' | 'selected' | 'all' = 'all') {
      const config: any = { ...this.gridOptions.exportConfig, type, mode };
      if (mode === 'all') config.data = await this.$model.dispatch('link/query');
      this.grid.exportData({ ...config, type, mode });
    },
    async onPrint(mode: 'current' | 'selected' | 'all' = 'all') {
      const config: any = { ...this.gridOptions.printConfig, mode };
      if (mode === 'all') config.data = await this.$model.dispatch('link/query');
      this.grid.print(config);
    },
    inputFile(newFile: any, oldFile: any, prevent: any) {      
      
      if (newFile && !oldFile) {
        this.$nextTick(function () {          
        })
      }
      if (!newFile && oldFile) {        
      }
    },
    inputFilter(newFile: any, oldFile: any, prevent: any) {
      if (newFile && !oldFile) {
        if (!/\.(gif|jpg|jpeg|png|webp)$/i.test(newFile.name)) {
          //this.alert('Your choice is not a picture')
          return prevent()
        }
      }
      if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
        newFile.url = ''
        const URL = window.URL || window.webkitURL        
        if (URL && URL.createObjectURL) {
          newFile.url = URL.createObjectURL(newFile.file)
          this.form.formData.Picture.Uri = newFile.url          
          //setTimeout(() => {
            // var ins = (document.getElementsByClassName('vxe-modal--box')[0])
            // var top = (window.screen.availHeight - ins.clientHeight) / 2;
            // console.log(top)
            // this.modal.setPosition(top)
          //}, 200);
          
        }
      }
    },
  }
});
