/* eslint-disable */

import { isArray, isFunction, isObject, isValue } from "./inspect" //isEmpty
import { hasOwnProperty } from "./object";

export const arrayEqual = (target, source) => {
    if (!target || !source) return false;
    if (target.length !== source.length) return false;
    for (const key in target) {
        const obj1 = target[key], obj2 = source[key];
        if (isArray(obj1) && isArray(obj2)) {
            if (!arrayEqual(obj1, source[key]))
                return false;
        } else if (isObject(obj1) && isObject(obj2)) {
            const targetEntries = Object.entries(obj1);
            const sourceEntries = Object.entries(obj2);
            if (targetEntries.length !== sourceEntries.length) return false;
            if (targetEntries.toString() !== sourceEntries.toString()) return false;
        } else if (obj1 !== obj2) {
            return false;
        }
    }
    return true;
}

// to be continued
export const looseEqual = (target, source) => {
    if (target === source) return undefined;

    if (isFunction(target) || isFunction(source))
        throw "錯誤的參數型態";

    if (isValue(target) || isValue(source))
        return target;

    const diff = {};
    // When the array are different, it will return the object which is compared to(target).
    if (isArray(source) && isArray(target)) {
        return arrayEqual(target, source) ? undefined : target;
    }

    for (const key in target) {
        if (isFunction(target[key])) continue;

        const value2 = hasOwnProperty(source, key) ? source[key] : undefined;
        const dd = looseEqual(target[key], value2);
        if (dd !== undefined) diff[key] = dd;
    }

    // for (const key in source) {
    //     if (isFunction(source[key]) || undefined !== diff[key]) {
    //         continue;
    //     }

    //     const dd = this.objectDifferences(undefined, source[key])
    //     if (!isEmpty(dd)) diff[key] = dd;
    // }

    return diff;

}